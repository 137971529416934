import React from 'react';
import ButtonMailto from './buttonEmail';

const AboutCard = ({ profile }) => {
  return (
    <div className='w-full md:w-1/3 pr-5 mb-40'>
      <div
        className='
            bg-white
            w-full
            rounded-3xl
            flex flex-col
            justify-around
            items-center
            shadow-lg
          '>
        <div className='flex justify-center items-center -mt-20 mb-8'>
          <img
            className='object-cover h-40 w-40 rounded-full shadow-lg'
            src={profile.profilePicture}
            alt={profile.name}
          />
        </div>
        <div className='w-full h-1/2 flex flex-col justify-center items-center'>
          <h1 className='text-gray-700 font-bold'>{profile.name}</h1>
          <h1 className='text-gray-500 text-sm'>{profile.title}</h1>
          <p className='pt-5 pb-8 px-8 text-center'>{profile.about}</p>
          {/* <a className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' href={`mailto:${profile.email}`}>Email Me</a> */}
        </div>
      </div>
    </div>
  );
};

export default AboutCard;

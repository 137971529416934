/**
 * see https://github.com/mapbox/mapbox-react-examples/blob/master/basic/src/Map.js
 */
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "../components/mapbox/Mapbox.css";
// import MapboxGLButtonControl from "../components/mapbox/MapboxControl";
import Layers from "../components/mapbox/Layers.js";
import LayerControl from "../components/mapbox/LayerControl.js";
import TerrainControl from "../components/mapbox/TerrainControl"
import Spinner from "../components/common/Spinner";
import { getLayerConfig } from "../components/mapbox/mglHelpers"
import ImageryToggleControl from "../components/mapbox/ImageryToggleControl"
// import SpeciesToggleControl from "../components/mapbox/speciesToggle";
import { mapClearHighlight, mapSetHighlight } from "../components/mapbox/mglHighlight"
import useCurrentUser from '../hooks/useCurrentUser';

//Geocoder
import searchIndex from '../components/mapbox/stream_search.json' //only 600kb now, so ~200kb/state roughly
import Fuse from "fuse.js";
import CheapRuler from "cheap-ruler";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYW5kcmV3Ym9lZGRla2VyIiwiYSI6ImNrNDliZzYxbjAyNGQzbW4yMnI4eDR3amsifQ.-p4QomGJMOINgPmR4zEQyw";

const Map = () => {

  const User = useCurrentUser()

  const [mapLayers, setLayers] = useState(Layers);
  const [isLoading, setIsLoading] = useState(false);
  const [mapIsLoading, setMapisLoading] = useState(true);

  const mapContainerRef = useRef(null);

  // Initialize map when component mounts
  useEffect(() => {
    if (User === null) return;

    const premiumUser = User.isPremium.isCurrent;
    // const premiumUser = false

    console.log("map useEffect");
    
    const LNG = -86.4376;
    const LAT = 42.271;
    const ZOOM = 7;
    const X = window.sessionStorage.getItem("lng")
      ? JSON.parse(window.sessionStorage.getItem("lng"))
      : LNG;
    const Y = window.sessionStorage.getItem("lat")
      ? JSON.parse(window.sessionStorage.getItem("lat"))
      : LAT;
    const Z = window.sessionStorage.getItem("zoom")
      ? JSON.parse(window.sessionStorage.getItem("zoom"))
      : ZOOM;

    const mapboxMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      // style: "mapbox://styles/mapbox/dark-v10",
      style: "mapbox://styles/andrewboeddeker/cl44lchlx000014pscgf5ecz1", //NOTE new premium access points and bridge points
      // style: "mapbox://styles/andrewboeddeker/ckus6hvs41vio17l8avdangl8",
      center: [+X, +Y],
      zoom: Z,
    });

    //ATTEMPTING TO FIX THE URL BAR HIDING AND MAP DOESNT RESIZE ISSUE
    const resizeObserver = new ResizeObserver(entries => {
      console.log('Body height changed:', entries[0].target.clientHeight);
      
      mapboxMap.resize()
    })
    resizeObserver.observe(document.body)  

    window.addEventListener('resize', () => {
      document.querySelector(".map-container").style.height = window.innerHeight + "px";
      mapboxMap.resize();
    });
    
    const mapInit = (e) => {
      console.log(mapboxMap.getStyle().layers)
      mapboxMap.on("zoomend", () => {
        document.querySelector(".mapboxgl-ctrl-attrib-inner").children[0].innerText = map.getZoom().toFixed(0) + " © Mapbox";
      })
      console.log("map loaded");
      console.log({User})
      const map = e.target;

      /**
       * Set visibility of the layers based on the status of the user
       */

      //TODO convert into its own helper function
      function mapHasLayer(layerId) {
        const layers = map.getStyle().layers;
        const ids = layers.reduce((i,l) => [...i, l.id], [])
        return (ids.includes(layerId)) ? true : false
      }

      mapLayers.forEach((ml) => {
        if (ml.premium && !premiumUser) {
          ml.layers.forEach(l => {
            if (mapHasLayer(l)) map.removeLayer(ml.layers[0])
            ml["disabled"] = true
          })
        }

        //ADD LAYER AS DEFINED FROM STYLE IN LAYERS
        if (!mapHasLayer(ml.layers[0])) {
          if (((ml.premium && premiumUser) || !ml.premium) && ml.layerStyles) {
            ml.layerStyles.forEach(s => {
              if (!map.getSource(s.source) && s._source) {
                console.log("adding source")
                map.addSource(s.source, s._source)
              }
              const beforeLayer = ml.beforeLayer ? ml.beforeLayer : "road-label-simple"
              map.addLayer(s, beforeLayer)
            })
          }
        }
        
        if (ml.popup) {
          map.on("mousemove", ml.layers[0], () => {
            map.getCanvas().style.cursor = "pointer";
          });
          map.on("mouseleave", ml.layers[0], () => {
            map.getCanvas().style.cursor = "";
          });
        }
      });

      setLayers(mapLayers)

      /*-------------------*/
      /**
       * Add map controls
       */
      /*-------------------*/

      //TODO move to helpers file
      const toGeoJSON = (array) => {
        return array.reduce((i,r) => {
          return [...i, {
            type: "Feature",
            text: r.n,
            place_name: `Stream: ${r.n}`,
            place_type: ["address"],
            center: [+r.x,+r.y],//[r.c.split(",").map(n=>+n)][0],
            geometry: {
              type: "Point",
              coordinates: [+r.x,+r.y]
            },
            properties: r
          }]
        }, [])
      }

      const sortJSON = (array, key) => {
        return array.sort((a, b) => {
            const x = a[key];
            const y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
      }

      const LocalGeocoder = (query) => {
        const {lng,lat} = map.getCenter();
        const ruler = new CheapRuler(lat, 'miles');
        const rulerIndex = searchIndex.slice();
        let timer = Date.now()
        rulerIndex.map(r => {
          return r["d"] = Number(ruler.distance([lng,lat], [+r.x,+r.y]).toFixed(2));
        });
        console.log("cheap-ruler", Date.now() - timer) //this is very fast!
        timer = Date.now()
        const options = {
          includeScore: true,
          keys: ['n'],
          minMatchCharLength: 3,
          threshold: 0.2
        }
        const fuse = new Fuse(rulerIndex, options)
        console.log("fuse index", Date.now() - timer)
        timer = Date.now()
        const results = fuse.search(query) //this is the "slowest...but still fast"
        console.log("fuse search", Date.now() - timer)
        timer = Date.now();
        console.log(results)
        const items = results.reduce((i,r) => [...i, r.item], []);
        sortJSON(items, 'd');
        const sliced = (items.length < 6) ? items : items.slice(0,5);
        return toGeoJSON(sliced);
      };
      //TODO add click event after geocoder returns results and that result is a stream
      //https://github.com/mapbox/mapbox-gl-geocoder/blob/master/API.md#parameters
      const geocoder = new MapboxGeocoder({
        localGeocoder: LocalGeocoder,
        accessToken: mapboxgl.accessToken,
        limit: 10,
        mapboxgl: mapboxgl,
        collapsed: true
      });
      map.addControl(geocoder, "top-right");
      
      //TODO try and find another fix for this
      // map.on("click", () => geocoder.clear());

      map.addControl(new mapboxgl.NavigationControl(), "top-right");
      
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true
        })
      );
      
      map.addControl(new TerrainControl(), "top-right")
      
      const imageryToggleControl = ImageryToggleControl(map)
      map.addControl(imageryToggleControl, "bottom-left");
      // map.addControl(SpeciesToggleControl(map, "map-streams-w-attributes-64se6m", "brook"), "top-left")
      
      /**
       * Custom "Home" button, disabled for now
       */
      // function zoomHome(event) {
      //   map.flyTo({
      //     center: [LNG, LAT],
      //     zoom: ZOOM,
      //   });
      // }
      // const HomeButtonControl = new MapboxGLButtonControl({
      //   className: "map-ctrl-home",
      //   title: "Zoom to Default Extent",
      //   eventHandler: zoomHome,
      // });
      // map.addControl(HomeButtonControl, "top-left");
      // console.log(mapLayers)
      const layerControl = LayerControl(map, mapLayers, premiumUser)
      map.addControl(layerControl, "bottom-right");
      /*-------------------
      
       End Add map controls

      -------------------*/

      /*-------------------

      Map Event Handlers

      --------------------*/

      /**
       * Store map center and zoom in session storage
       * so when the user navigates back to the map it is in the same place as when they left
       */
      map.on("move", () => {
        const lng = map.getCenter().lng.toFixed(4);
        const lat = map.getCenter().lat.toFixed(4);
        const zoom = map.getZoom().toFixed(2);
        window.sessionStorage.setItem("lng", lng);
        window.sessionStorage.setItem("lat", lat);
        window.sessionStorage.setItem("zoom", zoom);
      });

      //TODO add buffer to click target
      /**
       * Map click handler with popup templates stored in ../components/mapbox/Layers.js
       */
      map.on("click", async (e) => {
        mapClearHighlight(map)

        const bboxClickTargetSize = 15;
        const bbox =   [[e.point.x - bboxClickTargetSize / 2, e.point.y - bboxClickTargetSize / 2],[e.point.x + bboxClickTargetSize / 2, e.point.y + bboxClickTargetSize / 2]]
        // const bboxCoords = [map.unproject(bbox[0]),map.unproject(bbox[1])]; //used if you want to show the clicktaregt bounding box on the map
        const features = map.queryRenderedFeatures(bbox);
        console.log(features)
        // console.log(map.getStyle().layers)
        const {layer, feature} = getLayerConfig(mapLayers, features);
        console.log(layer)
        if (layer.length) {
          setIsLoading(true);
          mapSetHighlight(map, feature[0])
          const { html, lngLat } = await layer[0].popup(
            feature[0],
            e.lngLat,
            premiumUser
          );
          setIsLoading(false);
          if (!html) return
          new mapboxgl.Popup().setLngLat(lngLat).setHTML(html).addTo(map);
        }
      });

      setTimeout(function() {
        setMapisLoading(false)
      }, 500)
    };

    mapboxMap.on("load", mapInit);

    return () => mapboxMap.remove();
  }, [User]);

  return (
    <div>
      <div className="map-container" ref={mapContainerRef} />
      {(!User) ? (
        <div className='w-full h-screen flex justify-center items-center absolute bg-white transition-opacity'>
          <Spinner />
        </div>
      ) : (<div></div>)}
      {(mapIsLoading) ? (
        <div className='w-full h-screen flex justify-center items-center absolute bg-white transition-opacity'>
          <Spinner />
        </div>
      ) : (<div></div>)}
      {(isLoading) ? (
        <div className='w-full h-screen flex justify-center items-center'>
          <Spinner />
        </div>
      ) : (<div></div>)}
      {/* <LayerControl layers={layers} onVisibilityChange={setLayerVisibility} /> */}{" "}
    </div>
  );
};

export default Map;

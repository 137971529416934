import React from 'react';
import styles from '../styles/Home.module.css';
import AboutCard from '../components/landing/AboutCard';
import PageNav from '../components/common/PageNav';
import Footer from '../components/landing/Footer';
// import Header from '../components/landing/Header';
// import Mission from '../components/landing/Mission';
// Data
import { aboutData } from '../constants/variables';

/**
 *
 * @returns Used in about company page
 */

const About = () => {
  return (
    <div className={styles.container}>
      <PageNav />
      <div className='text-center mt-20 mb-4 container px-5'>
        <h2 className='mb-8 text-3xl font-semibold'>
          Improve. Explore. Catch More.
        </h2>
        <p className='text-gray-600 '>
          We aim to be on the forefront of technology and fly fishing. At Fly
          Fish Finder, we truly care for the environment we fish in, and for the
          fish we chase. Most fly anglers are like minded in this, and we are
          confident if we don't offer a solution like this, some mainstream app
          company eventually will and may take advantage of these special places
          and fish. As anglers ourselves and no stranger to the challenges this
          sport presents, we are excited about offering a tool that helps
          anglers Improve, Explore and ultimately Catch More fish at the end of
          the day. We understand how busy everyone is, and exploring quality
          places can be tough, but we firmly believe everyone deserves a shot.
          We won't quit here, we have plans to continue growing, partnering and
          offering the best tool out there for anglers.
        </p>
      </div>
      <main>
        <section className='text-gray-600 body-font'>
          <div className='container px-5 py-24 mx-auto'>
            {/* <Mission /> */}
            <div className='flex flex-wrap mb-20 mt-28'>
              {aboutData &&
                aboutData.map((profile) => (
                  <AboutCard key={profile.name} profile={profile} />
                ))}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default About;

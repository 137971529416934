import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoBlack from '../../img/logos/logoBlack.svg';
import heroBg from '../../img/landing/topography.svg';
import { SubScriptionModal } from './SubScriptionModal';
// import MailerLite from './MailerLite';

function Footer() {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <footer
      className='text-gray-50 body-font'
      style={{
        background: `linear-gradient(180deg, transparent 85%, rgba(255,255,255,1) 100%),url(${heroBg})
      `,
      }}>
      {/* Top footer with subscribe box */}
      <div className='pt-16 px-5 mx-auto border-t'>
        <div className=' container mx-auto flex flex-wrap md:text-left text-center order-first'>
          <div className='lg:w-1/4 md:w-1/2 w-full px-4'>
            <h2 className='title-font font-medium text-gray-900 tracking-widest text-sm mb-3'>
              Site
            </h2>
            <nav className='list-none mb-10'>
              {/* <li>
                <a
                  href='/explore'
                  className='text-gray-600 hover:text-gray-800'>
                  Explore
                </a>
              </li> */}
              <li>
                <a
                  id='blog'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.flyfishfinder.com/blog'
                  className='menu-item text-gray-600 hover:text-gray-800'>
                  Blog
                </a>
              </li>
              <li>
                <Link
                  to='/courses'
                  className='text-gray-600 hover:text-gray-800'>
                  Courses
                </Link>
              </li>
            </nav>
          </div>

          <div className='lg:w-1/4 md:w-1/2 w-full px-4'>
            <h2 className='title-font font-medium text-gray-900 tracking-widest text-sm mb-3'>
              About Us
            </h2>
            <nav className='list-none mb-10'>
              <li>
                <Link to='/about' className='text-gray-600 hover:text-gray-800'>
                  Company
                </Link>
              </li>
              <li>
                <Link
                  to='/partners'
                  className='text-gray-600 hover:text-gray-800'>
                  Partners
                </Link>
              </li>
            </nav>
          </div>

          <div className='lg:w-1/4 md:w-1/2 w-full px-4'>
            <h2 className='title-font font-medium text-gray-900 tracking-widest text-sm mb-3'>
              Terms
            </h2>
            <nav className='list-none mb-10'>
              <li>
                <Link to='/terms' className='text-gray-600 hover:text-gray-800'>
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link
                  to='/terms#privacy'
                  className='text-gray-600 hover:text-gray-800'>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to='/attribution'
                  className='text-gray-600 hover:text-gray-800'>
                  Attribution
                </Link>
              </li>
            </nav>
          </div>
          <div className='lg:w-1/4 md:w-1/2 w-full px-4'>
            <h2 className='title-font font-medium text-gray-900 tracking-widest text-sm mb-3 sm:mx-auto'>
              Get App updates and occassional fishy news from the community
            </h2>
            <button
              className=' sm:mx-auto md:flex justify-center px-4 py-2 border-2 text-base font-medium rounded-md text-white bg-brandPrimary hover:bg-brandPrimary md:px-6 mb-4 duration-300 hover:opacity-95 rounded-full'
              onClick={() => setModalOpen(true)}>
              Subscribe
            </button>
            {/* <MailerLite
              code={3813592}
              trackerId={'u1m6t3'}
              submitButtonContent='Submit'
              submittingButtonContent='Submitting...'
              submissionComplete={<div>You're subscribed!</div>}
              emailPlaceholder='Enter your email...'
            /> */}
          </div>
        </div>
      </div>
      {/* bottom gray footer */}
      <div className='bg-gray-50'>
        <div className='container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col'>
          <Link
            to='/'
            className='flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0'>
            <img src={logoBlack} alt='Logo' width={75} height={75} />
          </Link>
          <p className='text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4'>
            © 2021 Fly Fish Finder, LLC
          </p>
          {/* Adding give back campaign */}
          <div className='flex flex-col items-center mx-auto '>
            <img
              src='https://res.cloudinary.com/dauxj5oo3/image/upload/v1636724371/static-site-assets/public-site/landing/TDRlogo_zgoscv.png'
              alt='Theodore Roosevelt Conservation Foundation'
              className='my-2'
              width={200}
              height={100}
            />
            <p className='text-sm text-black  sm:mt-0 mt-4 '>
              We commit 1% of all pre-tax profits back to conservation.
            </p>
          </div>

          <span className='inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start'>
            <a
              className='text-gray-500'
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.facebook.com/FlyFishFinder'>
              <svg
                fill='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                className='w-5 h-5'
                viewBox='0 0 24 24'>
                <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
              </svg>
            </a>
            {/* <a
              className='ml-3 text-gray-500'
              >
              <svg
                fill='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                className='w-5 h-5'
                viewBox='0 0 24 24'>
                <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
              </svg>
            </a> */}
            <a
              className='ml-3 text-gray-500'
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/flyfishfinder/'>
              <svg
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                className='w-5 h-5'
                viewBox='0 0 24 24'>
                <rect width='20' height='20' x='2' y='2' rx='5' ry='5'></rect>
                <path d='M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01'></path>
              </svg>
            </a>
            {/* <a className='ml-3 text-gray-500'>
              <svg
                fill='currentColor'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='0'
                className='w-5 h-5'
                viewBox='0 0 24 24'>
                <path
                  stroke='none'
                  d='M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z'></path>
                <circle cx='4' cy='4' r='2' stroke='none'></circle>
              </svg>
            </a> */}
          </span>
        </div>
      </div>
      <SubScriptionModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </footer>
  );
}

export default Footer;

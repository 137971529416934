export const addContact = async (user, list) => {
  console.log(user.email)
  // const lists = await getListId()
  // console.log(lists)
  const options = {
    method: 'PUT',
    url: 'https://api.sendgrid.com/v3/marketing/contacts',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`,
    },
    body: {
      list_ids: ['b0ac1183-5a37-4754-9966-7d04928e42c6'],
      contacts: [
        {
          address_line_1: 'string (optional)',
          address_line_2: 'string (optional)',
          alternate_emails: [user.email],
          city: 'string (optional)',
          country: 'string (optional)',
          email: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
          postal_code: 'string (optional)',
          state_province_region: 'string (optional)',
          custom_fields: {},
        },
      ],
    },
    json: true,
  }

  fetch(options, function (error, response, body) {
    if (error) throw new Error(error)

    console.log(body)
  })
}

export const getListId = async (listName) => {
  var options = {
    method: 'GET',
    url: 'https://api.sendgrid.com/v3/marketing/lists',
    qs: { page_size: '100' },
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`,
    },
    body: {},
    json: true,
  }

  fetch(options, function (error, response, body) {
    if (error) throw new Error(error)

    console.log(body)
  })
}

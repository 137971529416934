import React, { useState, useEffect } from 'react'
import useCurrentUser from '../hooks/useCurrentUser'
import getQueryStringValue from '../helpers/getQueryStringValue'
import logoBlack from '../img/logos/logoBlack.svg'
import { withRouter } from 'react-router'

/**
 * URL params -> courseId:number, courseTitle:string, isPremium:boolean,
 * @returns Success page for courses and premium
 */

const PurchaseSuccess = (props) => {
  const { push } = props.history
  const [userToUpdate, setUserToUpdate] = useState(null)
  const [hasSaved, setHasSaved] = useState(false)
  const [isError, setIsError] = useState(false)

  // Get current users Data
  const loggedInUser = useCurrentUser()
  const { courseId, courseTitle, userIsPremium } = props.match.params

  // Set the current user to the user that is logged in to update
  useEffect(() => {
    if (loggedInUser !== null) {
      setUserToUpdate(loggedInUser)
    }
  }, [loggedInUser])

  // Once we have the currently logged in user update the user in the DB
  useEffect(() => {
    if (userToUpdate !== null) {
      // Establish URL params -> all will be strings
      // const userIsPremium = getQueryStringValue('isPremium')
      // const courseId = getQueryStringValue('courseId')
      // const courseTitle = getQueryStringValue('courseTitle')
      // const { courseId, courseTitle, userIsPremium } = props.match.params
      console.log(userIsPremium, courseId, courseTitle)

      // Update the current user based on params
      let currentUser = userToUpdate
      if (userIsPremium && userIsPremium === 'true') {
        currentUser = { ...currentUser, isPremium: true }
        currentUser = {
          ...currentUser,
          isPremium: {
            trialMembership: currentUser.isPremium.trialMembership,
            trialExp: currentUser.isPremium.trialExp,
            isCurrent: true,
          },
        }
        console.log('IS PREMIUM**********')
      }
      if (courseId) {
        currentUser = {
          ...currentUser,
          purchasedCourses: [...currentUser.purchasedCourses, courseId],
        }
        console.log('COURSE ID**********')
      }

      // save updated User
      updateUser({ currentUser })
    }
    // eslint-disable-next-line
  }, [userToUpdate])

  async function updateUser(userObj) {
    try {
      return new Promise((resolve, reject) => {
        fetch(`/.netlify/functions/updateUser`, {
          method: 'POST',
          body: JSON.stringify(userObj),
        })
          .then((res) => res.json())
          .then((data) => {
            // Updated UI
            setHasSaved(true)
            // Send user back to dashboard
            setTimeout(() => push('/dashboard-home'), 5000)
            // return resolve(data);
          })
      })
    } catch (error) {
      setIsError(true)
      console.error(error)
    }
  }

  const getContent = () => {
    // If there is an error
    if (isError) {
      return (
        <>
          <p className='text-center'>
            There was an error updating your profile, please refresh and try
            again. If the error persists please contact{' '}
            <a
              className='underline font-semibold'
              href='mailto:andrew@flyfishfinder.com'
              rel='noopener noreferrer'
              target='_blank'
            >
              {' '}
              customer service
            </a>
          </p>
        </>
      )
    }
    // Initial Load and currently saving
    if (!hasSaved) {
      return (
        <div className='w-full flex items-center justify-center'>
          <svg
            fill='none'
            class='w-6 h-6 animate-spin'
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              clip-rule='evenodd'
              d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
              fill='currentColor'
              fill-rule='evenodd'
            />
          </svg>

          <p>Please wait while we update your profile...</p>
        </div>
      )
    }
    // If the update was successful
    if (hasSaved) {
      return (
        <>
          <p className='text-center'>
            <b>
              Thank you for your purchase {courseTitle && `of ${courseTitle}`}
            </b>
            <br /> Your profile has successfully been updated, please wait while
            you are being redirected back to your dashboard ...
          </p>
        </>
      )
    }
  }

  return (
    <div
      className='flex flex-col items-center justify-center w-full h-screen '
      style={{ zIndex: 9999 }}
    >
      <img src={logoBlack} alt='Fly Fish Finder' className='h-20 w-20 mb-6' />
      <h1
        className={`${
          isError ? 'text-red-500 ' : 'text-green-500 '
        } text-3xl font-semibold mt-6'`}
      >
        {isError ? 'Error' : 'Purchase Successful'}
      </h1>
      {/* Information box */}
      <div
        className={`w-2/3 rounded-md py-2 px-6 flex items-center mt-6 mb-2 space-x-1 ${
          hasSaved
            ? 'bg-green-100 text-green-500'
            : isError
            ? 'bg-red-100 text-red-500'
            : 'bg-blue-100 text-blue-500'
        }`}
      >
        {getContent()}
      </div>
    </div>
  )
}

export default withRouter(PurchaseSuccess)

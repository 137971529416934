import MapboxControl from "./MapboxControl"

const ImageryToggleControl = (map) => {

  // const opacityStyle = map.getPaintProperty("map-streams-w-attributes-64se6m", "line-opacity")
  const opacityStyle2 = map.getPaintProperty("nhd_streams", "line-opacity");

  const imageryToggleControl = new MapboxControl({
    className: "imagery-toggle",
    title: "Toggle Imagery",
    clickHandler: function() {      
      // if (!map.getSource("bing-imagery")) {
      //   // console.log(map.getStyle().layers)
      //   map.addSource("bing-imagery", {
      //     type: "raster",
      //     tiles: [
      //       'https://ecn.t0.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z&key=AoWPrPMgyDRNfZmzu4Qja6BI83kD8MMHMOHQrclZA590qWHsZJjHd4DfLlI8e5wH',
      //       'https://ecn.t1.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z&key=AoWPrPMgyDRNfZmzu4Qja6BI83kD8MMHMOHQrclZA590qWHsZJjHd4DfLlI8e5wH',
      //       'https://ecn.t2.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=&key=AoWPrPMgyDRNfZmzu4Qja6BI83kD8MMHMOHQrclZA590qWHsZJjHd4DfLlI8e5wHz',
      //       'https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=&key=AoWPrPMgyDRNfZmzu4Qja6BI83kD8MMHMOHQrclZA590qWHsZJjHd4DfLlI8e5wHz',
      //     ],
      //     maxzoom: 23
      //   });
      //   map.addLayer({
      //     id: "bing-imagery",
      //     type: "raster",
      //     source: "bing-imagery",
      //     layout: {
      //       "visibility": 'none'
      //     }
      //   }, 'hillshade')
      // }

      const toggleLayers = [
        // {
        //   "id": "bing-imagery",
        //   "visibility": true,
        // },
        {
          "id": "satellite",
          "visibility": true
        }
      ];

      if (map.getLayoutProperty(toggleLayers[0].id, "visibility") === "none") {
        map.setPaintProperty("us_public_lands", "fill-opacity", 0.3)
        toggleLayers.forEach(l => {
          if (l.visibility) {
            const visiblility = (!l.visibility) ? "none" : "visible";
            map.setLayoutProperty(l.id, "visibility", visiblility);
          }
          // map.setPaintProperty("map-streams-w-attributes-64se6m", "line-opacity", [
          //   "interpolate",
          //   ["linear"],
          //   ["zoom"],
          //   0,
          //   0,
          //   7,
          //   0.4,
          //   10,
          //   0.6,
          //   15,
          //   0.2
          // ]);
          map.setPaintProperty("nhd_streams", "line-opacity", [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            0,
            7,
            1,
            14,
            0.6,
            15,
            0.3
          ])
        });
        map.moveLayer("nhd_streams", "hillshade")
        this.classList.add("streets");
        if (map.getLayer("hillshade")) map.setLayoutProperty("hillshade", "visibility", "none")
      }else{
        // map.setPaintProperty("map-streams-w-attributes-64se6m", "line-opacity", opacityStyle);
        map.setPaintProperty("nhd_streams", "line-opacity", opacityStyle2);
        map.moveLayer("nhd_streams", "water")
        map.setPaintProperty("us_public_lands", "fill-opacity", 0.8)
        toggleLayers.forEach(l => {
          if (l.visibility) {
            const visiblility = (!l.visibility) ? "visible" : "none";
            map.setLayoutProperty(l.id, "visibility", visiblility)
          }
        })
        this.classList.remove("streets");
        if (map.getLayer("hillshade")) map.setLayoutProperty("hillshade", "visibility", "visible")
      }
    }
  })
  return imageryToggleControl
};

export default ImageryToggleControl
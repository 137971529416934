import { useState, useEffect } from 'react';
import axios from 'axios';

/**
 * Get list of users from FFF Db
 * @returns A list of users currently register in FFF
 */

export default function useMembers() {
  const myStorage = window.sessionStorage;
  const [members, setMembers] = useState(null);

  useEffect(() => {
    axios
      .get(`/.netlify/functions/getUsers`)
      .then((res) => {
        setMembers(res.data);
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  }, [myStorage]);

  return members;
}

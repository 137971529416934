import React from 'react';

const TabNav = ({ sections, activeTab, setActiveTab, useSection = false }) => {
  return (
    <nav className='mt-6 mb-6 flex items-center flex-wrap'>
      {sections.length > 0 &&
        sections.map((section, i) => (
          <button
            key={i}
            onClick={() => setActiveTab(i)}
            href='#'
            className={`${
              activeTab !== i && 'hover:bg-gray-100 text-brandPrimary'
            }  rounded-lg  inline-block text-sm font-medium  px-3 py-2 leading-none ${
              activeTab === i && 'text-white bg-brandPrimary'
            }  mr-2`}>
            {useSection ? <>Section {i + 1}</> : section}
          </button>
        ))}
    </nav>
  );
};

export default TabNav;

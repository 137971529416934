import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import Spinner from "../components/common/Spinner";
import axios from "axios";
import { toast } from "react-toastify";
import MapPoint from "../icons/MapPoint";

/**
 * This component displays a users public profile
 * @returns Returns A users Public Profile
 */

const ProfilePage = (props) => {
  // Gets the users id from the URL Param
  const { id } = props.match.params;

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (id) {
      axios
        .get(`/.netlify/functions/getUserByAuthId?id=${id}`)
        .then((res) => {
          setCurrentUser(res.data);
        })
        .catch((error) => {
          console.error(error);
          toast.error(
            "Error Fetching Profile please refresh the page and try again. If the error continues please contact user support.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        });
    }
    return () => setCurrentUser(null);
  }, [id]);

  return (
    <main className="profile-page fade-in">
      {currentUser === null ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          {/* Hero Image */}
          <section className="relative block h-600px">
            <div
              className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage: `url(${
                  currentUser.coverImage.url || currentUser.coverImage
                })`,
                backgroundPosition: "center",
              }}
            >
              <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
              ></span>
            </div>
            <div
              className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70px"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-blueGray-100 fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
          </section>
          {/* Main profile section start */}
          <section className="relative py-16 bg-blueGray-100 profileContainer">
            <div className="container mx-auto px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                <div className="px-6">
                  <div className="flex flex-wrap justify-center">
                    {/* User Avatar */}
                    <div className="w-full flex justify-center h-40 w-40 -mt-20">
                      <img
                        alt="..."
                        src={
                          currentUser.profilePicture.url ||
                          currentUser.profilePicture
                        }
                        className="shadow-lg rounded-full border-none h-40 w-40 bg-gray-100"
                      />
                    </div>
                    {/* USER NAME & LOCATION */}
                    <div className="w-full text-center mt-12">
                      <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-0">
                        {currentUser.firstName} {currentUser.lastName}
                      </h3>
                      {currentUser.userLocation.locationName && (
                        <div className="text-md leading-normal mt-0 mb-4 text-blueGray-500 font-bold uppercase flex items-center justify-center">
                          <span className="mr-2">
                            <MapPoint />
                          </span>

                          {currentUser.userLocation.locationName}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Stats & BIO */}
                  <div className="grid grid-cols-1 md:grid-cols-2 my-12">
                    {/* Stats */}
                    <div className="p-4 md:p-8 border-r-0 md:border-r border-blueGray-200">
                      {currentUser.profileData.experienceLevel.value && (
                        <p className="text-blueGray-500 mt-4">
                          <span className="font-bold text-blueGray-700">
                            Years of Experience:{" "}
                          </span>{" "}
                          {currentUser.profileData.experienceLevel.value}
                        </p>
                      )}

                      <p className="text-blueGray-500 mt-2">
                        <span className="font-bold text-blueGray-700">
                          Fishing Region:{" "}
                        </span>{" "}
                        {currentUser.profileData.fishingLocation?.length > 0
                          ? currentUser.profileData.fishingLocation.map(
                              (region, i) => {
                                if (
                                  i + 1 !==
                                  currentUser.profileData.fishingLocation.length
                                ) {
                                  return <span key={i}>{region.value}, </span>;
                                } else {
                                  return <span key={i}>{region.value}</span>;
                                }
                              }
                            )
                          : "User has not entered a location yet"}
                      </p>

                      {currentUser.profileData.typeOfWater.length > 0 && (
                        <p className="text-blueGray-500 mt-2">
                          <span className="font-bold text-blueGray-700">
                            Type Of Water:{" "}
                          </span>{" "}
                          {currentUser.profileData.typeOfWater.map(
                            (type, i) => {
                              if (
                                i + 1 !==
                                currentUser.profileData.typeOfWater.length
                              ) {
                                return (
                                  <span key={type.value}>{type.value}, </span>
                                );
                              } else {
                                return (
                                  <span key={type.value}>{type.value}</span>
                                );
                              }
                            }
                          )}
                        </p>
                      )}
                      {currentUser.profileData.typesOfFish.length > 0 && (
                        <p className="text-blueGray-500 mt-2">
                          <span className="font-bold text-blueGray-700">
                            Types Of Fish:{" "}
                          </span>{" "}
                          {currentUser.profileData.typesOfFish.map(
                            (fish, i) => {
                              if (
                                i + 1 !==
                                currentUser.profileData.typesOfFish.length
                              ) {
                                return (
                                  <span key={fish.value}>{fish.value}, </span>
                                );
                              } else {
                                return (
                                  <span key={fish.value}>{fish.value}</span>
                                );
                              }
                            }
                          )}
                        </p>
                      )}

                      <p className="text-blueGray-500 mt-2">
                        <span className="font-bold text-blueGray-700">
                          Catch & Release:{" "}
                        </span>
                        {currentUser.profileData.catchAndRelease === true
                          ? "Yes"
                          : "No"}
                      </p>
                      {currentUser.profileData.fishingStyle.length > 0 && (
                        <p className="text-blueGray-500 mt-2">
                          <span className="font-bold text-blueGray-700">
                            Preferred Technique/Style:{" "}
                          </span>{" "}
                          {currentUser.profileData.fishingStyle[0].value}
                        </p>
                      )}
                    </div>
                    {/* BIO */}
                    <div className="p-4 md:p-8">
                      <p className="text-blueGray-700 my-2 font-bold">BIO</p>
                      <p className="text-md leading-relaxed text-blueGray-500">
                        {currentUser.bio.length > 0
                          ? currentUser.bio
                          : "User has not entered a bio yet"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </main>
  );
};

export default withRouter(ProfilePage);

import axios from "axios";

const uploadToCloudinary = async (file, type, url) => {
  // const cloudName = process.env.REACT_CLOUDINARY_CLOUD_NAME;
  // const apiKey = process.env.REACT_CLOUDINARY_API_KEY;
  // const secret = process.env.REACT_CLOUDINARY_SECRET;

  // Create form data
  console.log("payload", file, type, url);

  let data = new FormData();
  data.append("image", file);

  // data.append("api_key", 714716599278282);
  // data.append("cloud_name", "dauxj5oo3");
  // data.append("api_secret", "ozhknL96P1sdD2xnGoQs_8_t-54");
  // if (type === "PROFILE_PICTURE") {
  //   data.append("upload_preset", "profile_pictures");
  // }
  // if (type === "COVER_PHOTO") {
  //   data.append("upload_preset", "cover_photos");
  // }

  // Send file to cloudinary
  // alert(url);

  return axios.post(
    `https://us-central1-fly-fish-finder.cloudfunctions.net/uploadImage`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export default uploadToCloudinary;

import Header from '../landing/Header';
import { Link } from 'react-router-dom';

const PageNav = () => {
  return (
    <div>
      <header
        className='text-gray-500 body-font flex md:flex-row pr-5 w-full z-50'
        style={
          {
            // background: getStickyClasses('bg'),
            // position: getStickyClasses('pos'),
            // boxShadow: getStickyClasses('shadow'),
          }
        }>
        <Header />
        <div className='text-gray-500 body-font flex md:flex-row items-center content-center'>
          <Link to='/auth'>
            <button className='w-24 h-2 flex items-center justify-center px-4 py-4 border border-transparent text-base font-medium rounded-md text-white bg-brandPrimary hover:bg-brandPrimary md:px-6 mb-4 duration-300 hover:opacity-95 rounded-full'>
              Log in
            </button>
          </Link>
        </div>
      </header>
    </div>
  );
};

export default PageNav;

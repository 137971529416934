import DashboardIcon from '../icons/DashboardIcon';
import UsersIcon from '../icons/UsersIcon';
import SettingsIcon from '../icons/SettingsIcon';
import CoursesIcon from '../icons/CoursesIcon';
import Map from '../icons/Map';
import Hand from '../icons/Hand';

/**
 * Routes used in the sideBar Component
 */

export const sideNavRoutes = [
  {
    icon: <DashboardIcon />,
    name: 'Dashboard',
    route: '/dashboard-home',
  },
  {
    icon: <UsersIcon />,
    name: 'Anglers',
    route: '/anglers',
  },
  {
    icon: <CoursesIcon />,
    name: 'Courses',
    route: '/courses-dashboard',
  },
  {
    icon: <Map />,
    name: 'Map',
    route: '/map',
  },
  {
    icon: <Hand />,
    name: 'Fly Tying',
    route: '/fly-tying-view',
  },
  {
    icon: <SettingsIcon />,
    name: 'Settings',
    route: '/settings',
  },
];

import React, { Fragment } from 'react';
import Flame from '../../icons/Flame';
import CardHeader from '../common/CardHeader';
import { Link } from 'react-router-dom';
import useMembers from '../../hooks/useMembers';

/**
 *
 * @returns Dashboard card with top users
 */

const TopUsersCard = ({ isPremium }) => {
  const members = useMembers();
  return (
    <div className='col-span-full md:col-span-1 bg-white rounded-xl shadow-md py-4 px-8 mt-10'>
      <CardHeader title='Find Anglers' icon={<Flame />} />
      <div className='mt-6 overflow-y-auto'>
        {members !== null &&
          // members.slice(0, 4).map((user, i) => {
          members.map((user, i) => {
            if (!user.showProfile) return <Fragment key={i}></Fragment>;
            return (
              <Link
                to={`/user-profile/${user.auth0_ID}`}
                // to={isPremium ? `/user-profile/${user.auth0_ID}` : '#'}
                key={i}>
                <div className='border-gray-400 grid grid-cols-3 items-center mb-2 w-full select-none cursor-pointer bg-blueGray-50 rounded-md p-4 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-lg'>
                  <div
                    className='rounded-md w-12 h-12 bg-gray-300 col-span-full md:col-span-1'
                    style={{}}>
                    <img
                      src={user.profilePicture.url || user.profilePicture}
                      alt='User '
                      className='rounded-md w-12 h-12'
                    />
                  </div>
                  <div className='font-semibold col-span-full md:col-span-2 text-left'>
                    {user.firstName} {user.lastName}
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default TopUsersCard;

import React from 'react';

const ArrowRight = ({ ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-6 w-6'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
      {...rest}>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M13 7l5 5m0 0l-5 5m5-5H6'
      />
    </svg>
  );
};

export default ArrowRight;

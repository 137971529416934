import React from 'react'
import './index.css'
import { useAuth0 } from '@auth0/auth0-react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './routes/PrivateRoute'
// Layouts
import Dashboard from './layouts/Dashboard'
// Unauthenticated pages
// import LandingPage from './pages/LandingPage';
import Auth from './pages/Auth'
// import Terms from './pages/Terms';
// import Partners from './pages/Partners';
// import Attribution from './pages/Attribution';
// import About from './pages/About';
// Authenticated views
import DashboardLanding from './views/DashboardLanding'
import Users from './views/Users'
import Courses from './views/Courses'
import Settings from './views/Settings'
import CourseTemplate from './views/CourseTemplate'
import ProfilePage from './views/ProfilePage'
import Map from './views/Map'
import PurchaseSuccess from './views/PurchaseSuccess'
import PrivateAbout from './views/About'
import SeoBlock from './components/common/SeoBlock'
import FlyTying from './views/FlyTying'

/**
 *
 * @returns Base for routing for authenticated and unauthenticated users system and layout
 */

function App() {
  const { isAuthenticated, user } = useAuth0()
  const myStorage = window.sessionStorage

  if (isAuthenticated === true) {
    myStorage.setItem('isLoggedIn', 'true')
    myStorage.setItem(
      'userId',
      user.sub.substring(user.sub.lastIndexOf('|') + 1)
    )
    localStorage.setItem('user', JSON.stringify(user))
  }

  return (
    <>
      <div className='h-screen'>
        <SeoBlock />
        <BrowserRouter>
          <>
            {/* <Route exact path='/' component={LandingPage} /> */}
            <Route exact path='/' component={Auth} />
            {/* <Route exact path='/about' component={About} />
            <Route exact path='/partners' component={Partners} />
            <Route exact path='/terms' component={Terms} />
            <Route exact path='/attribution' component={Attribution} />
            <Route exact path='/courses' component={Courses} />
            <Route exact path='/fly-tying' component={FlyTying} /> */}
            <Route
              path='/purchase-success/:courseId/:courseTitle/:userIsPremium'
              component={PurchaseSuccess}
            />
            {/* HOC For dashboard view */}
            <Dashboard>
              <Switch>
                <PrivateRoute
                  path={['/dashboard-home', '/dashboard-home/:code/:state']}
                  component={DashboardLanding}
                />
                <PrivateRoute path='/about' component={PrivateAbout} />
                <PrivateRoute path='/anglers' component={Users} />
                <PrivateRoute path='/courses-dashboard' component={Courses} />
                <PrivateRoute path='/settings' component={Settings} />
                <PrivateRoute path='/map' component={Map} />
                <PrivateRoute path='/course/:id' component={CourseTemplate} />
                <PrivateRoute
                  path='/user-profile/:id'
                  component={ProfilePage}
                />
                <Route exact path='/fly-tying-view' component={FlyTying} />
              </Switch>
            </Dashboard>
          </>
        </BrowserRouter>
      </div>
    </>
  )
}

export default App

import React, { useState } from 'react'
import CardHeader from '../common/CardHeader'
import BadgeCheck from '../../icons/BadgeCheck'
import ArrowRight from '../../icons/ArrowRight'
import { premiumSignupData } from '../../constants/variables'
import stripeLogo from '../../img/logos/stripeLogo.svg'

/**
 * Param {*} handleTrialClick -> Function to show trial modal and update user
 * @returns Premium members ship sign up card
 */

const PremiumSignUpCard = ({ setPremiumModalOpen, currentUser }) => {
  const [selected, setSelected] = useState('annually')
  const [purchaseUrl, setPurchaseUrl] = useState(
    'https://buy.stripe.com/6oE9DhdbwfMDgDK7ss'
  )

  const handleTypeSelect = (e) => {
    const { name } = e.target
    // Set the selected type
    setSelected(name)
    // Establish URL
    let url = ''
    if (name === 'monthly') {
      // Test Url
      url = 'https://buy.stripe.com/8wM2aP2wS0RJ4V26op' // -> live URL
      // url = 'https://buy.stripe.com/test_cN2g0I2cjbyzbu0144'; // -> test URL
    } else {
      // Test Url
      url = 'https://buy.stripe.com/6oE9DhdbwfMDgDK7ss' // -> live URL
      // url = 'https://buy.stripe.com/test_bIY5m43gnfOPfKg7st'; // -> test URL
    }
    // Set selected premium URL
    setPurchaseUrl(url)
  }
  const getTrialButton = () => {
    if (currentUser === null) return

    const { isPremium } = currentUser
    // Return trial button if the user has not had a free trial yet
    if (!isPremium.trialMembership) {
      return (
        <button
          onClick={setPremiumModalOpen}
          className='mt-3 bg-white border border-brandPrimary hover:bg-brandPrimary hover:shadow-md px-5  py-2 text-xs font-medium tracking-wider text-brandPrimary hover:text-white rounded-full transition ease-in duration-300'
        >
          Try Premium Free For 7 Days
        </button>
      )
    }
  }

  return (
    <div className='col-span-full md:col-span-2 bg-white py-8 px-8 rounded-xl shadow-md mt-10'>
      <CardHeader title='Level Up' icon={<BadgeCheck />} />
      <div className='flex items-center justify-around mt-6'>
        <div className='space-y-2'>
          <h3 className='text-2xl font-semibold text-gray-800'>
            Make the Most of Fly Fish Finder with Premium
          </h3>
          <h3 className='text-lg font-semibold text-gray-500'>
            With Premium you will get...
          </h3>
          {premiumSignupData &&
            premiumSignupData.map((item, i) => (
              <div className='flex text-gray-500 mr-3' key={i}>
                <div className='w-1 mr-3 mt-1 text-brandPrimary'>
                  <ArrowRight />
                </div>
                <p className='ml-3 '>{item}</p>
              </div>
            ))}
          {/* Price Selection  */}
          <div className='my-5 text-gray-500'>
            <div className='flex mb-3'>
              <label className='flex radio p-2 cursor-pointer'>
                <input
                  checked={selected === 'monthly'}
                  className='my-auto transform scale-125 color-brandPrimary'
                  type='radio'
                  name='monthly'
                  onChange={handleTypeSelect}
                />
                <div className='title px-2'>
                  Monthly - 4.99<small>/mo</small>{' '}
                </div>
              </label>
              <label className='flex radio p-2 cursor-pointer'>
                <input
                  checked={selected === 'annually'}
                  className='my-auto transform scale-125 color-brandPrimary'
                  type='radio'
                  name='annually'
                  onChange={handleTypeSelect}
                />
                <div className='title px-2'>
                  Annually - 49.99<small>/yr</small>{' '}
                </div>
              </label>
            </div>
            <a
              href={purchaseUrl}
              className='mr-3 mt-3 bg-brandPrimary hover:bg-brandPrimary hover:shadow-md px-5 py-2 text-xs font-medium tracking-wider text-white rounded-full transition ease-in duration-300'
            >
              Get Premium
            </a>
            {getTrialButton()}
          </div>
          <div className='text-gray-500 pt-2 flex items-center'>
            <p>Secured checkouts powered by </p>
            <img className='h-8' src={stripeLogo} alt='Stripe' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PremiumSignUpCard

/**
 * Returns all the current map layers in an array
 * @param {*} map 
 */
 function mapGetLayers(map) {
  const layers = map.getStyle().layers;
  return layers.reduce((i,l) => [...i, l.id], [])
}
/**
 * Check if a Mapbox GL JS map has a layer id in its current style
 * @param {object} map Mapbox GL map 
 * @param {string} layer string name of the layer id 
 */
function mapHasLayer(map, layer) {
  var layers = mapGetLayers(map);
  var hasLayer = false;
  if (layers.indexOf(layer) > -1) hasLayer = true;
  return hasLayer;
}

const getLayerConfig = (mapLayers, queriedFeatures) => {
  const mapPopupFeaturesList = [];
  mapLayers.forEach((ml) => {
    if (ml.popup) {
      mapPopupFeaturesList.push(ml.layers[0]);
    }
  })
  const queriedFeatureIds = queriedFeatures.reduce((i, f) => [...i, f.layer.id], []);
  console.log(queriedFeatureIds)
  const popupFeatures = queriedFeatureIds.filter((f) =>
    mapPopupFeaturesList.includes(f)
  );
  if (popupFeatures.length) {
    const layer = mapLayers.filter(
      (l) => l.layers[0] === popupFeatures[0]
    );
    const feature = queriedFeatures.filter(
      (f) => f.layer.id === popupFeatures[0]
    );
    return {layer, feature}
  }else{
    return {layer: [], feature: []}
  }
}

export { 
  mapHasLayer,
  mapGetLayers,
  getLayerConfig
};



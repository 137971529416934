import React from 'react';

const Spinner = () => {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <div className='w-12 h-12 border-4 border-brandPrimary rounded-full loader '></div>
    </div>
  );
};

export default Spinner;

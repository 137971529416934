/**
 *
 * @param {*} userObj -> Updated object of user settings
 * @returns updated user
 */

export default async function updateUser(userObj) {
  console.log(userObj);
  try {
    return new Promise((resolve, reject) => {
      fetch(`/.netlify/functions/updateUser`, {
        method: "POST",
        body: JSON.stringify(userObj),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("update user data", data);
          return resolve(data);
        });
    });
  } catch (error) {
    console.error(error);
  }
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CardHeader from "../components/common/CardHeader";
import Spinner from "../components/common/Spinner";
import CustomInput from "../components/utility/CustomInput";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import Switch from "react-ios-switch";
// Icons
import Photograph from "../icons/Photograph";
import UserIcon from "../icons/UserIcon";
import Globe from "../icons/Globe";
import Save from "../icons/Save";
import SettingsIcon from "../icons/SettingsIcon";
// Option Variables
import {
  levels,
  waterTypes,
  fishingStyles,
  fishTypes,
  selectStyles,
  fishingLocations,
} from "../constants/variables/profileOptions";
// Hooks & Helpers
import useCurrentUser from "../hooks/useCurrentUser";
import updateUser from "../helpers/updateUser";
import uploadToCloudinary from "../helpers/uploadToCloudinary";
import removeFromFirebase from "../helpers/removeFromCloudinary";
// Leaflet
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import axios from "axios";

/**
 *
 * @returns Forms for the user to update there person and profile data
 */

function Settings() {
  // Get current users Data
  const loggedInUser = useCurrentUser();
  let coverImageRef;
  let profilePicRef;

  const [currentUser, setCurrentUser] = useState(null);
  let [profilePictureFile, setProfilePictureFile] = useState(null);
  let [coverPictureFile, setCoverPictureFile] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [needToSave, setNeedToSave] = useState(false);

  const [loadingUploadImage, setloadingUploadImage] = useState(false);

  console.log("currentUser :>> ", currentUser);

  // Set currentUser to loggedInUsers data to be updated locally
  useEffect(() => {
    if (loggedInUser !== null) {
      setCurrentUser(loggedInUser);
      setIsLoading(false);
    }
    // unmount cleanup
    return () => {
      setCurrentUser(null);
      setIsLoading(true);
    };
  }, [loggedInUser]);
  // Triggers save button to show
  useEffect(() => {
    if (currentUser !== loggedInUser) {
      setNeedToSave(true);
    }
    // unmount cleanup
    return () => setNeedToSave(false);
  }, [currentUser, loggedInUser]);

  const onChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      // For nested profile data
      case "catchAndRelease":
        setCurrentUser({
          ...currentUser,
          profileData: {
            ...currentUser.profileData,
            [name]: value === "true" ? true : false,
          },
        });
        break;
      case "fishingLocation":
        setCurrentUser({
          ...currentUser,
          profileData: {
            ...currentUser.profileData,
            [name]: value,
          },
        });
        break;
      // for regular profile data
      default:
        setCurrentUser({
          ...currentUser,
          [name]: value,
        });
        break;
    }
  };
  const onSelectChange = (e, name) => {
    setCurrentUser({
      ...currentUser,
      profileData: {
        ...currentUser.profileData,
        [name]: e,
      },
    });
  };
  const handleShowProfileToggle = () => {
    setCurrentUser({
      ...currentUser,
      showProfile: !currentUser.showProfile,
    });
  };
  const addUserLocation = async (selection) => {
    const config = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type,Accept",
      "access-control-allow-methods": "GET, POST, PUT, DELETE",
    };
    const location = await axios.get(
      `https://nominatim.openstreetmap.org/reverse.php?lat=${selection.latlng.lat}&lon=${selection.latlng.lng}&zoom=18&format=jsonv2`,
      config
    );

    // Destructure city and state from response
    const { state } = location.data.address;
    // Set the users location
    setCurrentUser((currentUser) => ({
      ...currentUser,
      userLocation: {
        ...selection.latlng,
        locationName: `${state}`,
      },
    }));
  };
  const handleFileSelect = (e) => {
    let old_url = currentUser.profilePicture;

    const { name, files } = e.target;

    const objectUrl = URL.createObjectURL(files[0]);

    let type;
    // Set the correct type to upload
    if (name === "coverImage") {
      type = "COVER_PHOTO";

      setCoverPictureFile({
        type: type,
        name: name,
        files: files[0],
        old_url: old_url,
      });
      setCurrentUser({
        ...currentUser,
        [name]: objectUrl,
      });

      // If the user has uploaded a picture before delete it
      // if (typeof currentUser.coverImage !== "string") {
      //   removeFromFirebase(currentUser.coverImage.public_id);

      // }
    } else {
      type = "PROFILE_PICTURE";

      console.log("setting up the state", files[0], name, old_url);
      setProfilePictureFile({
        type: type,
        name: name,
        files: files[0],
        old_url: old_url,
      });
      // If the user has uploaded a picture before delete it
      // if (typeof currentUser.profilePicture !== "string") {
      //   removeFromFirebase(currentUser.profilePicture.public_id);
      // }

      setCurrentUser({
        ...currentUser,
        [name]: objectUrl,
      });
    }

    // Upload new image and set it to user
    // uploadToCloudinary(files[0], type).then((res) => {
    //   setCurrentUser({
    //     ...currentUser,
    //     [name]: res,
    //   });
    // });
  };
  // Saves user information
  const updateProfile = () => {
    let userObj = { currentUser };
    let tempUserObj = { ...userObj };

    console.log("user object", userObj);
    setloadingUploadImage(true);

    if (profilePictureFile && coverPictureFile) {
      const profilePicturePromise = uploadToCloudinary(
        profilePictureFile.files,
        profilePictureFile.type,
        profilePictureFile.old_url
      );

      const coverPicturePromise = uploadToCloudinary(
        coverPictureFile.files,
        coverPictureFile.type,
        coverPictureFile.old_url
      );

      removeFromFirebase(profilePictureFile.old_url);
      removeFromFirebase(coverPictureFile.old_url);

      Promise.all([profilePicturePromise, coverPicturePromise]).then((res) => {
        console.log("both images", res);
        setCurrentUser({
          ...currentUser,
          [profilePictureFile.name]: res[0].data.response.image_url,
          [coverPictureFile.name]: res[1].data.response.image_url,
        });

        tempUserObj.currentUser.profilePicture = res[0].data.response.image_url;
        tempUserObj.currentUser.coverImage = res[1].data.response.image_url;
        UpdateUserProfile(tempUserObj);
        setloadingUploadImage(false);
      });

      setProfilePictureFile(null);
    } else if (profilePictureFile) {
      uploadToCloudinary(
        profilePictureFile.files,
        profilePictureFile.type,
        profilePictureFile.old_url
      ).then((res) => {
        removeFromFirebase(profilePictureFile.old_url);
        setCurrentUser({
          ...currentUser,
          [profilePictureFile.name]: res.data.response.image_url,
        });

        tempUserObj.currentUser.profilePicture = res.data.response.image_url;
        console.log("updated temp user", tempUserObj);
        UpdateUserProfile(tempUserObj);
        setloadingUploadImage(false);
      });
      setProfilePictureFile(null);
    } else if (coverPictureFile) {
      uploadToCloudinary(
        coverPictureFile.files,
        coverPictureFile.type,
        coverPictureFile.old_url
      ).then((res) => {
        removeFromFirebase(coverPictureFile.old_url);
        setCurrentUser({
          ...currentUser,
          [coverPictureFile.name]: res.data.response.image_url,
        });

        tempUserObj.currentUser.coverImage = res.data.response.image_url;

        UpdateUserProfile(tempUserObj);
        setloadingUploadImage(false);
      });
    }
  };

  const UpdateUserProfile = (userObject) => {
    console.log("object in UpdateUser", userObject);
    updateUser(userObject).then((res) => {
      if (res.status === 200) {
        // Reset Save button
        console.log("UpdateUserProfileNetlify", res);

        setNeedToSave(false);
        // Notify User
        toast.success("Successfully saved profile data.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <div className="flex h-full w-full fade-in relative">
      {isLoading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="bg-white-200 w-full mb-20">
          {/* Header Image */}
          <div
            style={{
              backgroundImage: `url(${
                currentUser.coverImage.url || currentUser.coverImage
              })`,
            }}
            className="relative h-64 bg-gray-200 w-full bg-cover bg-center flex items-center justify-center"
          ></div>
          <div className="mx-auto">
            {/* Profile image */}
            <img
              className=" shadow-lg relative rounded-full profile_photo mx-auto h-40 w-40 -mt-20 bg-gray-100"
              src={
                currentUser.profilePicture?.url || currentUser.profilePicture
              }
              alt={currentUser.firstName}
            />
            <div className="w-full max-w-4xl p-6 mx-auto mt-8">
              <div className="flex justify-center  mb-6">
                <Link
                  to={`/user-profile/${currentUser.auth0_ID}`}
                  className="mx-auto rounded-full bg-brandPrimary text-white text-sm rounded py-2 px-4 edit tracking-wider font-semibold"
                >
                  View Public Profile
                </Link>
              </div>

              {/* Profile settings Andrew removed from behind currentUser this .isPremium.isCurrent so toggle is visible to free and premium now. */}
              {currentUser && (
                <div className="w-full bg-white rounded-xl shadow-md py-4 px-8">
                  <CardHeader
                    title="Profile Settings"
                    icon={<SettingsIcon />}
                  />

                  <p className="text-blueGray-500 mb-2 font-semibold text-sm mt-6">
                    Display profile publicly
                  </p>
                  <label className="flex items-center text-gray-500">
                    <span className="mr-2">No</span>
                    <Switch
                      checked={currentUser.showProfile}
                      onChange={handleShowProfileToggle}
                      style={{ cursor: "pointer !important" }}
                    />
                    <span className="ml-2">Yes</span>
                  </label>
                </div>
              )}

              {/* Images */}
              <div className="w-full bg-white rounded-xl shadow-md py-4 px-8 mt-8">
                <CardHeader title="Profile Images" icon={<Photograph />} />
                {/* information details about URL's */}
                {/* <div className='rounded-md py-2 px-4 bg-blue-100 flex items-start text-blue-500 mt-6 mb-2'>
                  <span className='mr-2 text-blue-500'>
                    <InformationCircle />
                  </span>
                  Cover Image and Profile Pictures must be an URL to a picture
                  that is hosted. For example you can use google drive,
                  gravatar, or dropbox.
                </div> */}
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div>
                    <p className="text-blueGray-500 mb-2 font-semibold text-sm mt-6">
                      Cover Image
                    </p>
                    <input
                      type="file"
                      hidden={true}
                      className="hidden"
                      onChange={handleFileSelect}
                      name="coverImage"
                      ref={(refParam) => (coverImageRef = refParam)}
                    />
                    <button
                      className="mx-auto rounded-full bg-greyTertiary text-blueSecondary text-sm rounded-md py-2 px-4 tracking-wider font-semibold"
                      onClick={() => coverImageRef.click()}
                    >
                      Select Cover Image
                    </button>
                  </div>

                  <div>
                    <p className="text-blueGray-500 mb-2 font-semibold text-sm mt-6">
                      Profile Picture
                    </p>
                    <input
                      type="file"
                      hidden={true}
                      className="hidden"
                      onChange={handleFileSelect}
                      name="profilePicture"
                      ref={(refParam) => (profilePicRef = refParam)}
                    />
                    <button
                      className="mx-auto rounded-full bg-greyTertiary text-blueSecondary text-sm rounded-md py-2 px-4 tracking-wider font-semibold"
                      onClick={() => profilePicRef.click()}
                    >
                      Select Profile Picture
                    </button>
                  </div>
                </div>

                {/* <CustomInput
                  value={currentUser.coverImage}
                  onChange={onChange}
                  name='coverImage'
                  wrapperClasses='mt-4'
                  label='Cover Image'
                />
                <CustomInput
                  value={currentUser.profilePicture}
                  onChange={onChange}
                  name='profilePicture'
                  wrapperClasses='mt-4'
                  label='Profile Picture'
                /> */}
              </div>
              {/* Personal Information */}
              <div className="w-full bg-white rounded-xl shadow-md py-4 px-8 mt-8">
                <CardHeader title="Personal Information" icon={<UserIcon />} />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <CustomInput
                    value={currentUser.firstName}
                    onChange={onChange}
                    name="firstName"
                    wrapperClasses="mt-4"
                    label="First Name"
                  />
                  <CustomInput
                    value={currentUser.lastName}
                    onChange={onChange}
                    name="lastName"
                    wrapperClasses="mt-4"
                    label="Last Name"
                  />
                </div>
                <CustomInput
                  value={currentUser.bio}
                  onChange={onChange}
                  name="bio"
                  wrapperClasses="mt-4"
                  type="textarea"
                  label="BIO"
                  placeholder="Add a bio to let everyone know a little about you"
                />
                <p className="text-blueGray-500 mb-2 font-semibold text-sm mt-4">
                  Location
                </p>
                <div className="border border-3 border-gray-00 rounded-lg h-500px overflow-hidden">
                  <MapContainer
                    center={[
                      currentUser.userLocation.lat,
                      currentUser.userLocation.lng,
                    ]}
                    zoom={13}
                    whenReady={(map) => {
                      map.target.on("click", function (e) {
                        addUserLocation(e);
                      });
                    }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                      position={[
                        currentUser.userLocation.lat,
                        currentUser.userLocation.lng,
                      ]}
                    >
                      <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                      </Popup>
                    </Marker>
                  </MapContainer>
                </div>
              </div>
              {/* Fishing Information */}
              <div className="w-full bg-white rounded-xl shadow-md py-4 px-8 mt-8">
                <CardHeader title="Fishing Information" icon={<Globe />} />
                <p className="text-blueGray-500 mb-2 font-semibold text-sm mt-6">
                  Fishing Region
                </p>
                <Select
                  options={fishingLocations}
                  value={currentUser.profileData.fishingLocation}
                  styles={selectStyles}
                  onChange={(e) => onSelectChange(e, "fishingLocation")}
                  isMulti
                />
                <div className="mt-3">
                  <p className="text-blueGray-500 mb-2 font-semibold text-sm">
                    Experience Level
                  </p>
                  <Select
                    options={levels}
                    value={currentUser.profileData.experienceLevel}
                    styles={selectStyles}
                    onChange={(e) => onSelectChange(e, "experienceLevel")}
                  />
                </div>
                <div className="mt-3">
                  <p className="text-blueGray-500 mb-2 font-semibold text-sm">
                    Types of Water
                  </p>
                  <Select
                    options={waterTypes}
                    value={currentUser.profileData.typeOfWater}
                    styles={selectStyles}
                    onChange={(e) => onSelectChange(e, "typeOfWater")}
                    isMulti
                  />
                </div>
                <div className="mt-3">
                  <p className="text-blueGray-500 mb-2 font-semibold text-sm">
                    Types of Fish
                  </p>
                  <CreatableSelect
                    options={fishTypes}
                    value={currentUser.profileData.typesOfFish}
                    styles={selectStyles}
                    onChange={(e) => onSelectChange(e, "typesOfFish")}
                    isMulti
                  />
                </div>
                <div className="mt-3">
                  <p className="text-blueGray-500 mb-2 font-semibold text-sm">
                    Preferred Techniques/Style
                  </p>
                  <Select
                    options={fishingStyles}
                    value={currentUser.profileData.fishingStyle}
                    styles={selectStyles}
                    onChange={(e) => onSelectChange(e, "fishingStyle")}
                    isMulti
                  />
                </div>
                <div className="mt-3">
                  <p className="text-blueGray-500 mb-2 font-semibold text-sm">
                    Catch And Release
                  </p>
                  <div className="flex mb-3">
                    <label className="flex radio cursor-pointer">
                      <input
                        checked={
                          currentUser.profileData.catchAndRelease === true
                        }
                        className="my-auto transform scale-125 color-brandPrimary"
                        type="radio"
                        name="catchAndRelease"
                        value={true}
                        onChange={onChange}
                      />
                      <div className="title px-2">Yes</div>
                    </label>
                    <label className="flex radio cursor-pointer">
                      <input
                        checked={
                          currentUser.profileData.catchAndRelease === false
                        }
                        className="my-auto transform scale-125 color-brandPrimary"
                        type="radio"
                        name="catchAndRelease"
                        value={false}
                        onChange={onChange}
                      />
                      <div className="title px-2">No</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Save Button */}
      {needToSave && (
        <button
          disabled={loadingUploadImage}
          className="text-white px-4 w-auto h-12 bg-green-500 rounded-full hover:bg-green-600 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none fixed bottom-6 right-10 flex items-center fade-in"
          onClick={updateProfile}
        >
          {loadingUploadImage ? (
            "Saving..."
          ) : (
            <>
              <Save />
              <span className="ml-2">Save</span>
            </>
          )}{" "}
        </button>
      )}
    </div>
  );
}

export default Settings;

import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { sideNavRoutes } from '../routes/sideNavRoutes';
import LogOutIcon from '../icons/LogOutIcon';
import logoWhite from '../img/logos/logoWhite.svg';

const SideBar = (props) => {
  const { logout } = useAuth0();
  const { pathname } = props.location;
  const { isAuthenticated } = props;

  const handleLogOut = () => {
    logout({ returnTo: window.location.origin });
    window.sessionStorage.setItem('isLoggedIn', 'false');
    window.sessionStorage.clear();
    window.localStorage.clear();
  };

  return (
    <>
      {isAuthenticated && (
        <div
          className={`hidden md:block fixed flex flex-col left-0 w-14 bg-slate-600 dark:bg-gray-900 h-full text-white transition-all duration-300 border-none z-10 sidebar ${
            pathname === '/purchase-success' && 'hidden'
          }`}>
          <div className='overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow h-screen'>
            <ul className='flex flex-col py-4'>
              <li className='sidebar-icon-wrapper pb-1'>
                <img
                  src={logoWhite}
                  alt='fly fish finder logo'
                  className='sidebar-icon'
                />
              </li>
              {/* Routes / Links */}
              {sideNavRoutes &&
                sideNavRoutes.map((route) => (
                  <li
                    key={route.name}
                    className='focus:outline-none focus:border-0'>
                    <Link
                      className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-brandPrimary text-white-600 hover:text-white-800 pr-6 transition-all duration-300 ease focus:border-0`}
                      to={route.route}>
                      <div
                        className={`inline-flex justify-center items-center ${
                          pathname === route.route
                            ? 'bg-brandPrimary text-white-800 p-2 rounded ml-2'
                            : 'ml-4 '
                        }`}>
                        <span className='w-6'>{route.icon}</span>
                      </div>
                      <span
                        className={`text-sm tracking-wider truncate  ${
                          pathname === route.route ? 'font-bold ml-3' : 'ml-5'
                        }`}>
                        {route.name}
                      </span>
                    </Link>
                  </li>
                ))}
            </ul>
            {/* Log Out */}
            <ul className='mb-10'>
              <li className='mt-auto'>
                <a
                  href='#!'
                  onClick={handleLogOut}
                  className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-brandPrimary dark:hover:bg-gray-600 text-white-600 hover:text-white-800  pr-6 transition-all duration-300 ease`}>
                  <span className='inline-flex justify-center items-center ml-3 w-6'>
                    <LogOutIcon />
                  </span>
                  <span className='ml-2 text-sm tracking-wide truncate'>
                    Log Out
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(SideBar);

import ReactPlayer from 'react-player';
import ViewHeader from '../components/common/ViewHeader';
import PageNav from '../components/common/PageNav';
import PageHeader from '../components/common/PageHeader';
import SeoBlock from '../components/common/SeoBlock';
import { flyTyingData } from '../constants/flyTying';

/**
 *
 * @returns Page that displays fly tying videos
 */

const FlyTying = (props) => {
  const { pathname } = props.location;
  return (
    <>
      <SeoBlock pageTitle='Fly Tying | Fly Fish Finder' />
      <div
        className={` ${
          pathname !== '/fly-tying' ? 'viewContainer' : 'mx-auto px-20'
        }
            } fade-in`}>
        {pathname === '/fly-tying' ? (
          <div className='mb-20'>
            <PageNav />
            <PageHeader
              pageTitle='Fly Tying'
              subTitle='We offer select courses by experienced fly anglers to enhance your skills'
            />
          </div>
        ) : (
          <div className='flex flex-wrap md:justify-between'>
            <ViewHeader title='Fly Tying' />
            <div
              className='xl:w-1/2 md:w-full xl:mt-4 md:mt-0
         xl:mb-0 md:mb-8'>
              <p className='text-xl text-gray-700 pl-4'>
                Top tiers from around the country showcasing some of their favorite patterns.
              </p>
            </div>
          </div>
        )}

        {/* Mapping through the flyTyingData and displaying video cards */}
        <div className='mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-10 xl-grid-cols-4 gap-y-10 gap-x-6 '>
          {flyTyingData &&
            flyTyingData.map((video, i) => (
              <>
                <div
                  key={i}
                  className='container mx-auto shadow-lg rounded-lg max-w-md hover:shadow-2xl transition duration-300 overflow-hidden'>
                  <div className='player-wrapper'>
                    <ReactPlayer
                      url={video.video}
                      className='react-player'
                      height='100%'
                      width='100%'
                    />
                  </div>

                  <div className='p-6'>
                    <h1 className='md:text-1xl text-xl hover:text-indigo-600 transition duration-200  font-bold text-gray-900 '>
                      {video.title}
                    </h1>
                    <p className='text-gray-500 my-2 hover-text-900 '>
                      {video.description}
                    </p>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default FlyTying;

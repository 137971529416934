export const aboutData = [
  {
    name: 'Andrew Boeddeker',
    title: 'CEO',
    about:
      'Born in southern MN and a lifetime resident of the area, he began his journey in the world of spin fishing until a friend in college introduced him to the fly rod.  For the past eight years he has been fishing the Driftless area’s small streams for trout, and recently been exploring the world of 8 weight rods and bass, carp and pike on the fly.  He is passionate about connecting technology with fly fishing, and Fly Fish Finder was an ideal pursuit to blend the two. Andrew is a firm believer that most of the best days on the water are with other people, those are the memories that stick with you the longest. While not working or fishing, he spends most of his time with his wife and two young daughters.',
    profilePicture:
      'https://res.cloudinary.com/dauxj5oo3/image/upload/v1629644891/static-site-assets/public-site/landing/Andrew_Profile_v2_zzhxho.png',
    email:
      'andrew@flyfishfinder.com',
  },
  {
    name: 'Brett Downs',
    title: 'Content and Social Media',
    about:
      'A native of Colorado Springs, CO he has been blessed to fish some of the top fly fishing waters in the country. Currently he is pursuing a business major from the University of Montana in Missoula, and between classes and work he finds himself exploring the many types of streams and mountain lakes that Montana has to offer. He has a sincere love for the sport and enjoys taking friends out for a first time experience on the water.  He has a knack with the camera and always seems to find a little bit bigger fish with each adventure.  Despite his age, he ties flies like he’s been at it for decades, no joke!',
    profilePicture:
      'https://res.cloudinary.com/dauxj5oo3/image/upload/v1629639705/static-site-assets/public-site/landing/image0_4_uwytiv.jpg',
    email:
    'brett.t.downs4@gmail.com',
  },
  {
    name: 'Quincy Milton',
    title: 'Biologist',
    about:
      'Quincy Milton was born and raised on the West coast and recently made the move to New England for college and work. Having grown up in the Pacific Northwest, Quincy was constantly involved in trout and steelhead fishing the North West’s best rivers. After moving out to New England, Quincy was introduced to the productivity of northeastern Atlantic waters, specifically for striped bass. Additionally, Quincy has a passion for conservation and ocean preservation that is manifested in his biology degree. He has grown to have an extensive knowledge of fish biology and behavior through the hobby of fly fishing and by trade. Quincy is looking forward to sharing that knowledge with others who may benefit from it. ',
    profilePicture:
      'https://res.cloudinary.com/dauxj5oo3/image/upload/v1637287881/static-site-assets/public-site/landing/quincy_taludk.jpg',
  },
];
export const premiumSignupData = [
  'Pro Guide Access to the maps with all features and future enhancements including easements layer, parking, species finder and stream ratings to name a few.',
  'Unlimited Networking with other anglers. Unlimited chat, push notifications and the ability to quickly scan the map and locate anglers across the US which has been a game changer for many when it comes to trip planning.',
  'Future Perks! We are working on potential discounts to gear from local retailers and other benefits on the horizon.',
];
export const freePricingData = [
  // 'Basic Map',
  'Public Bridge Access Points',
  'Get Weather & Directions',
  'Satellite and 3D Toggles',
  'Topographic Layer',
  'Angler Profile (Toggle Public or Private)',
  // 'Fishing Piers (Coming Soon!)',
  // 'Basic Trails & Camping',
  'Upgrade Anytime',
  'Free 7 day Premium trial available on Dashboard'
];
export const premiumPricingData = [
  'Everything in Free',
  'Nearby Fish Finder (species filter)',
  'Unique Access Areas',
  'Nationwide Trails Filter',
  'Premium map layers for Streams, Easements and Stream Searches',
  'Stream Classifications (including Gold waters, Silver, Bronze and Other)',
  'Trout Section Toggle',
  'Stream Gages with Realtime Data',
  'Nearby Parking',
  'Advanced Public Lands Layer',
  'Boat Ramps',
  'Public vs Private water (Coming!)',
  'Unlimited User to User Chat (Coming!)',
  // 'Push Notifications (Coming!)',
  // 'Community reports & content',
];

// export const mapPricingData = [
//   'Everything in Free',
//   'Nearby Fish Finder (species filter)',
//   'Premium map layers for Streams, Easements and Stream Searches',
//   'Stream Classifications (including Gold waters, Silver, Bronze and Other)',
//   'Trout Section Toggle',
//   'Stream Gages with Realtime Data',
//   'Nearby Parking',
//   'Advanced Public Lands Layer',
//   'Satellite & 3D Toggles',
//   'Boat Ramps',
//   'Public vs Private water (Coming!)',
//   'Trails and Unique Access Layer (Coming!)',
//   // 'Push Notifications (Coming!)',
//   // 'Community reports & content',
// ];

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import TabNav from '../components/common/TabNav';
import ViewHeader from '../components/common/ViewHeader';
import CourseCard from '../components/courses/CourseCard';
import PageNav from '../components/common/PageNav';
import PageHeader from '../components/common/PageHeader';
import useCurrentUser from '../hooks/useCurrentUser';
import { InformationCircle } from '../icons/InformationCircle';
// Data
import { availableCourses } from '../constants/courses';
import EmptyState from '../components/common/EmptyState';
import SeoBlock from '../components/common/SeoBlock';
import Spinner from '../components/common/Spinner';

const tabs = ['Available', 'Purchased'];

/**
 *
 * @returns Page for the current users purchased courses and available courses -> used for both public & private facing views
 */

const Courses = (props) => {
  const { pathname } = props.location;

  const [activeTab, setActiveTab] = useState(0);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [coursesForUser, setCoursesForUser] = useState(availableCourses);
  const [isLoading, setIsLoading] = useState(true);

  // Get current users Data
  const loggedInUser = useCurrentUser();

  useEffect(() => {
    // Filter out the users purchased courses to display correct information
    if (loggedInUser !== null) {
      if (loggedInUser.purchasedCourses.length > 0) {
        // Destructure courses for more readable code
        const { purchasedCourses } = loggedInUser;
        // Filter the courses
        const usersPurchasedCourses = availableCourses.filter((c) =>
          purchasedCourses.includes(c.id.toString())
        );
        const usersNotPurchasedCourses = availableCourses.filter(
          (c) => !purchasedCourses.includes(c.id.toString())
        );
        // Update courses for user
        setPurchasedCourses(usersPurchasedCourses);
        setCoursesForUser(usersNotPurchasedCourses);
      }
    }
  }, [loggedInUser]);

  // Mock loading resources
  useEffect(() => {
    demoAsyncCall().then(() => setIsLoading(false));
  }, []);

  const demoAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 1000));
  };

  const getTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <>
            {coursesForUser.map((course) => (
              <CourseCard key={course.name} course={course} />
            ))}
          </>
        );
      case 1:
        // If the User has not purchased courses return empty state
        if (purchasedCourses.length === 0)
          return (
            <EmptyState
              text='You have not purchased any courses yet'
              subText='Check out our available courses, to see what you would like to master next'
            />
          );
        // Else return the purchased courses
        return (
          <>
            {purchasedCourses.map((course) => (
              <CourseCard key={course.name} course={course} purchased />
            ))}
          </>
        );
      default:
        break;
    }
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SeoBlock pageTitle='Courses | Fly Fish Finder' />
          {/* For landing page */}
          {pathname === '/courses' && (
            <div className='mb-20'>
              <PageNav />
              <PageHeader
                pageTitle='Courses'
                subTitle='We offer select courses by experienced fly anglers to enhance your skills'
              />
            </div>
          )}
          <section
            className={`${
              pathname !== '/courses' ? 'viewContainer' : 'mx-auto px-20'
            } fade-in`}>
            {/* Header for view **NOT LANDING PAGE** */}
            <div className='flex flex-wrap md:justify-between'>
              {pathname !== '/courses' && <ViewHeader title='Courses' />}
              <div
                className='xl:w-1/2 md:w-full xl:mt-4 md:mt-0
         xl:mb-0 md:mb-8'>
                <p className='text-xl text-gray-700 pl-4'>
                  Our courses are curated by fly anglers from a variety of
                  backgrounds, but who are all dedicated to helping you get more
                  out of the sport!
                </p>
              </div>
            </div>
            {pathname === '/courses' && (
              <span className='rounded-md py-2 px-4 bg-blue-100 flex items-start text-blue-500 mt-6 mb-2 w-full md:w-1/2'>
                <span className='mr-2 text-blue-500'>
                  <InformationCircle />
                </span>
                Sign up for a free account and get access to purchase our
                amazing courses
              </span>
            )}
            {/* If on landing page show only */}
            {pathname === '/courses' ? (
              <>
                <div className='flex flex-wrap mt-4 fade-in'>
                  {availableCourses.map((course) => (
                    <CourseCard key={course.name} course={course} isPublic />
                  ))}
                </div>
              </>
            ) : (
              <>
                {/* Coupon code for course purchase */}
                {loggedInUser && loggedInUser.purchasedCourses.length > 0 && (
                  <span className='rounded-md py-2 px-4 bg-blue-100 flex items-start text-blue-500 mt-6 mb-2 w-full md:w-1/2'>
                    <span className='mr-2 text-blue-500'>
                      <InformationCircle />
                    </span>
                    <span>
                      As a thank you gift, Fly Fish Finder has partnered with
                      Blue Line Co. to offer you a discount on the linked bundle of
                      their top streamers. Just enter discount code <b>FFF20</b>{' '}
                      at checkout.
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='ml-2 font-bold underline'
                        href='https://www.bluelineflies.com/bundles/trout-getting-started-eakwx'>
                        Visit Blue Line Co. Now
                      </a>
                    </span>
                  </span>
                )}

                <TabNav
                  sections={tabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <div className='flex flex-wrap fade-in'>
                  {/* Courses Cards */}
                  {getTabContent()}
                </div>
              </>
            )}
          </section>
        </>
      )}
    </>
  );
};

export default withRouter(Courses);

import React from 'react';
import emptyState from '.././../img/EmptyState.svg';

/**
 *
 * @param {text:mandatory,subText:optional} -> Used to tell user why they see the empty state
 * @returns A place holder for empty areas... I.E. Courses
 */

const EmptyState = ({ text, subText }) => {
  return (
    <div className='flex flex-col justify-center items-center h-auto w-full fade-in mt-10'>
      <img src={emptyState} alt='empty' className='mb-6' />
      <h1 className='text-3xl text-blueGray-700 mb-2'>{text}</h1>
      {subText && <h3 className='text-xl text-blueGray-500'>{subText}</h3>}
    </div>
  );
};

export default EmptyState;

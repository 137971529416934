import React, { useState, useEffect } from 'react';
import Spinner from '../components/common/Spinner';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import { courses, sliderSettings } from '../constants/courses/courses';
import TabNav from '../components/common/TabNav';
import Questions from '../components/courses/Questions';

/*Q
 *
 * @param {id} From Url Params -> Number
 * @returns Template for all courses
 */

const CourseTemplate = (props) => {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [tabLoading, setTabLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [course, setCourse] = useState({
    name: '',
    about: '',
    sections: [],
    recommendedVideos: [],
  });

  console.log('course :>> ', course);

  useEffect(() => {
    const paramId = parseInt(id);
    const currentCourse = courses.find((course) => course.id === paramId);
    setCourse({ ...currentCourse });
  }, [id]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    setTabLoading(true);
    setTimeout(() => setTabLoading(false), 500);
  };

  const getTabContent = () => {
    // Spinner for loading content
    if (tabLoading) {
      return (
        <div className='w-full h-10 flex justify-center items-center'>
          <Spinner />
        </div>
      );
    }
    // Show content
    return (
      <div className=' fade-in'>
        {/* Section Title */}
        <h3 className='text-lg font-semibold text-gray-700 my-2'>
          {course.sections[activeTab].title}
        </h3>
        {/* Paragraphs & content */}
        <div
          className='text-md text-gray-600 mb-6 font-normal'
          dangerouslySetInnerHTML={{
            __html: course.sections[activeTab].content,
          }}
        />
        {/* Section video */}
        {course.sections[activeTab].video !== null && (
          <div className='w-full flex justify-center '>
            <div className='w-full bg-gray-300 mb-8 player-wrapper'>
              <ReactPlayer
                controls
                url={course.sections[activeTab].video}
                className='react-player'
                height='100%'
                width='100%'
              />
            </div>
          </div>
        )}
        {/* Section Image */}
        {course.sections[activeTab].images !== null && (
          <Slider {...sliderSettings}>
            {course.sections[activeTab].images.map((image, i) => (
              <a
                key={i}
                className='px-3 cursor-pointer'
                href={image.url}
                target='_blank'
                rel='noreferrer'>
                <div className='bg-gray-300 h-48'>
                  <img src={image.url} alt='selected' />
                </div>
                <h3 className='text-sm text-gray-500 mt-6'>{image.caption}</h3>
              </a>
            ))}
          </Slider>
        )}
        {course.sections[activeTab].questions &&
          course.sections[activeTab].questions.length > 0 && (
            <section>
              <hr className='w-40 my-14 border-4 border-brandPrimary rounded-md sm:mx-0 mx-auto' />
              <h1 className='text-3xl font-bold text-blueSecondary mb-10'>
                Section Questions
              </h1>
              <Questions questions={course.sections[activeTab].questions} />
            </section>
          )}
      </div>
    );
  };
  return (
    <div>
      {isLoading ? (
        <div className='w-full h-screen flex justify-center items-center'>
          <Spinner />
        </div>
      ) : (
        <div className='viewContainer'>
          <header className='px-6 md:px-0'>
            {/* <!-- Section Hero --> */}
            <div
              className='p-3 md:p-0 mt-8 container mx-auto bg-gray-400 h-96 rounded-md flex items-center'
              style={{
                background: `url(https://res.cloudinary.com/dauxj5oo3/image/upload/v1629387508/static-site-assets/public-site/landing/david_holmes_trout_brrdus.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}>
              <div className='sm:m-20 text-gray-50 text-center sm:text-left'>
                <h1 className='text-5xl font-bold mb-4'>{course.name}</h1>
                {/* <p className='text-lg inline-block sm:block'>{about}</p> */}
                {/* <button className='mt-8 px-4 py-2 bg-brandprimary rounded'>
                  Some Action
                </button> */}
              </div>
            </div>
          </header>
          <main className='py-16 container mx-auto px-6 md:px-0'>
            {/* Course Content */}
            <section>
              <h1 className='inline-block text-blueSecondary font-bold text-3xl mb-6'>
                About Course
              </h1>
              <p className='text-md inline-block sm:block text-gray-600 mb-10'>
                {course.about}
              </p>
              <h1 className='inline-block text-blueSecondary font-bold text-3xl'>
                Course content
              </h1>
              {/* Sections */}
              <TabNav
                sections={course.sections}
                activeTab={activeTab}
                setActiveTab={handleTabChange}
                useSection
              />
              {/* Current section content */}
              {getTabContent()}
              {/* Recommended videos */}
              {course.recommendedVideos.length > 0 && (
                <section>
                  <hr className='w-40 my-14 border-4 border-brandPrimary rounded-md sm:mx-0 mx-auto' />
                  <h1 className='text-3xl font-bold text-blueSecondary mb-10'>
                    Recommended Videos
                  </h1>
                  <Slider {...sliderSettings}>
                    {course.recommendedVideos.map((video, i) => (
                      <div key={i} className='px-3'>
                        <div className='bg-gray-300 h-48'>
                          <ReactPlayer
                            controls
                            url={video.url}
                            height='100%'
                            width='100%'
                          />
                        </div>
                        <h3 className='text-sm text-gray-500 mt-2'>
                          {video.title}
                        </h3>
                      </div>
                    ))}
                  </Slider>
                </section>
              )}
            </section>
          </main>
        </div>
      )}
    </div>
  );
};

export default CourseTemplate;

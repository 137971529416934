import React from 'react';
import CardHeader from '../common/CardHeader';
import MapPoint from '../../icons/MapPoint';
import Map from '../../icons/Map';

/**
 *
 * @returns List of the top fished spots
 */

const TopSpotsCard = () => {
  // TODO - need to add onClick event to route to spot on map
  return (
    <div className='col-span-full md:col-span-2 bg-white py-8 px-8 rounded-xl shadow-md mt-10'>
      <CardHeader title='Top Spots' icon={<MapPoint />} />
      <div className='mt-4'>
        <div className='select-none cursor-pointer rounded-md flex flex-1 items-center py-4 pr-4'>
          <div
            className='flex flex-col rounded-md w-10 h-10 bg-brandPrimary justify-center items-center mr-4 text-white'
            style={{}}>
            <Map />
          </div>
          <div className='flex-1 pl-1'>
            <div className='font-medium'>Easement Access Root River</div>
            <div className='text-gray-600 text-sm'>
              Lanesboro, MN
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSpotsCard;

import React from 'react';
import { Helmet } from 'react-helmet';

/**
 *
 * @param {*} pageTitle -> title you want the page to have
 * @param {*} description -> description you want the page to have
 * @param {*} keywords -> keywords you want the page to have
 * @param {*} type -> type you want the page to have
 * @returns
 */

const SeoBlock = ({ pageTitle, description, keywords, type }) => {
  return (
    <Helmet>
      <html lang='en' />
      <title>
        {pageTitle
          ? pageTitle
          : 'Fly Fish Finder | The Top Rated and Most Comprehensive Fly Fishing Software'}
      </title>
      <meta
        name='description'
        content={
          description ||
          'Navigate new water. Join the community. Catch more fish. The most comprehensive fly fishing software on the market today.'
        }
      />
      <meta
        name='keywords'
        content={
          keywords ||
          'Fly Fish Finder, Spots to fish, Fly Fishing, Streamers, Anglers, Community, fly tying, Fly fishing software, fishing app, outdoors app, fishing platform'
        }
      />
      <meta name='robots' content='index, follow' />
      <meta name='theme-color' content='#E6E6FA' />
      <meta property='og:type' content={type || 'website'} />
      <meta name='viewport' content='width=device-width,initial-scale=1.0' />
      <link rel='canonical' href={`https://www.flyfishfinder.com`} />

      {/* Og:tags are for linking social media and other options */}
      <meta property="og:title" content={pageTitle || 'Fly Fish Finder'} />
      <meta
        property="og:description"
        content={
          description ||
          'Navigate new water. Join the community. Catch more fish. The most comprehensive fly fishing software on the market today.'
        }
      />
      <meta property="og:site_name" content="https://www.flyfishfinder.com" />
      {/* Social Media */}
      {/* FACEBOOK */}
      <meta property="og:title" content="Fly Fish Finder On Face Book" />
      <meta property="og:description" content="Fly Fishing Networking and Mapping App For Anglers" />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/dauxj5oo3/image/upload/v1638795568/static-site-assets/public-site/landing/The_Top_rated_fly_fishing_app_1_ejzhsi.png"
      />
      <meta
        property="og:url"
        content="https://www.facebook.com/FlyFishFinder"
      />
      {/* INSTAGRAM */}
      <meta property="og:title" content="Fly Fish Finder On Instagram" />
      <meta property="og:description" content="Fly Fishing App For Anglers" />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/dauxj5oo3/image/upload/v1638795568/static-site-assets/public-site/landing/The_Top_rated_fly_fishing_app_1_ejzhsi.png"
      />
      <meta
        property="og:url"
        content="https://www.instagram.com/flyfishfinder/"
      />
      {/* TWITTER */}
      {/* <meta name='twitter:title' content='TITLE OF POST OR PAGE' />
      <meta name='twitter:description' content='DESCRIPTION OF PAGE CONTENT' />
      <meta name='twitter:image' content='LINK TO IMAGE' />
      <meta name='twitter:site' content='@USERNAME' />
      <meta name='twitter:creator' content='@USERNAME' /> */}
    </Helmet>
  );
};

export default SeoBlock;

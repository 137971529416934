import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import createUser from '../helpers/createUser';
import SideBar from '../components/SideBar';
import MobileSideBar from '../components/MobileSideBar';
import Spinner from '../components/common/Spinner';
// Util functions
import useCurrentUser from '../hooks/useCurrentUser';
import BackButton from '../components/common/BackButton';

function Dashboard(props) {
  const { children } = props;
  const { isAuthenticated } = useAuth0();
  const userInfo = JSON.parse(localStorage.getItem('user'));

  const [isFinderUser, setIsFinderUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [userStorageInfo, setUserStorageInfo] = useState(null);

  // Used for if the user is currently a fff member and logging in
  const user = useCurrentUser();
  console.log('userStorageInfo :>> ', userStorageInfo);
  console.log('isAuthenticated :>> ', isAuthenticated);

  useEffect(() => {
    if (userInfo && userStorageInfo === null) {
      setUserStorageInfo(userInfo);
    }
  }, [userInfo, setUserStorageInfo, userStorageInfo]);

  // Checks if this user is in our DB (Logged in for the first time)
  useEffect(() => {
    if (userStorageInfo) {
      axios
        .get(`/.netlify/functions/checkUser?email=${userStorageInfo.email}`)
        .then((res) => {
          console.log('Checked if finder user', JSON.parse(res.data));
          setIsFinderUser(JSON.parse(res.data));
        })
        .catch((err) => {
          console.log('error ' + err);
        });
    }
  }, [userStorageInfo]);

  useEffect(() => {
    // Prevent these functions from being called before the user information is seeded
    if (userStorageInfo) {
      //create new user in OUR DB if this is the firs the the user has logged in
      if (isAuthenticated && isFinderUser === false) {
        console.log('creating user :>>>>>>>>>>>>>>>>>> ');
        createUser(userStorageInfo, setCurrentUser);
      }
      // If the user is authenticated and exists in DB then set that user to the currentUser
      if (isAuthenticated && isFinderUser && user !== null) {
        console.log('setting user :>>>>>>>>>>>>>>>>>> ');
        setCurrentUser(user);
      }
    }
  }, [isAuthenticated, isFinderUser, userStorageInfo, user]);

  return (
    <>
      {currentUser === null ? (
        <div
          className={`
        ${
          !isAuthenticated
            ? 'hidden'
            : 'w-full h-screen flex justify-center items-center'
        }
        `}>
          <Spinner />
        </div>
      ) : (
        <div
          className={`${
            !isAuthenticated ? 'hidden' : 'flex h-full overflow-hidden relative'
          } `}>
          <SideBar
            isAuthenticated={isAuthenticated}
            isPremium={currentUser.isPremium}
          />
          <MobileSideBar
            isAuthenticated={isAuthenticated}
            isPremium={currentUser.isPremium}
          />

          <div
            className={`${
              !isAuthenticated
                ? 'hidden'
                : 'w-full h-screen bg-blueGray-50 overflow-y-auto relative'
            } `}>
            <div className='back-btn'>
              <BackButton />
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;

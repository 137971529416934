import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Spinner from '../components/common/Spinner';

/**
 * Used for projecting routes from users that are not authenticated
 * @returns A protected route based on if the user is authenticated
 */

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <Spinner />
        ) : isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to='/' />
        )
      }
    />
  );
};

export default PrivateRoute;

import Modal from 'react-modal';

/**
 * Modals for email subscription
 */

export const SubScriptionModal = ({ modalOpen, setModalOpen }) => (
  <Modal
    isOpen={modalOpen}
    onRequestClose={() => setModalOpen(false)}
    contentLabel='Subscription Modal'>
    <iframe
      title='subscription modal'
      style={{
        width: '100%',
        height: '100%',
      }}
      frameborder='0'
      webkitallowfullscreen='true'
      mozallowfullscreen='true'
      allowfullscreen='true'
      src='https://cdn.forms-content.sg-form.com/3586f542-4c05-11ec-9578-06ecb4ed1cc7'></iframe>
  </Modal>
);

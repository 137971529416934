import React, { useState, useEffect } from 'react';
import PremiumSignUpCard from '../components/dashboard/PremiumSignUpCard';
import TopSpotsCard from '../components/dashboard/TopSpotsCard';
import TopUsersCard from '../components/dashboard/TopUsersCard';
import StatCard from '../components/common/StatCard';
import Alert from '../components/utility/alert';
import { addContact } from '../helpers/sendgrid';

import {
  PremiumExpirationModal,
  PremiumModal,
  FirstLoginModal,
} from '../components/dashboard/Modals';
import { toast } from 'react-toastify';

// Variables & Hooks
import { statData } from '../constants/statData';
import useCurrentUser from '../hooks/useCurrentUser';
import updateUser from '../helpers/updateUser';

/**
 * This is the landing page the user lands on after logging in
 */

const DashboardLanding = () => {
  const loggedInUser = useCurrentUser();

  const trialExpDate = new Date();
  trialExpDate.setDate(trialExpDate.getDate() + 7);

  const [premiumModalOpen, setPremiumModalOpen] = useState(false);
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  const [user, setUser] = useState(null);

  const setupProfile = user?.profileData?.fishingLocation;

  // Check if user has expired free trial
  useEffect(() => {
    if (loggedInUser !== null) {
      setUser(loggedInUser);
      // Check if the user has a trial membership
      if (loggedInUser.isPremium.trialMembership === true) {
        const today = new Date().toLocaleDateString('en-US');

        // Check if the users membership has expired
        if (today === loggedInUser.isPremium.trialExp) {
          handleRemoveTrial();
        }
      }
      // add user to the newsletter.
      // user selected to opt in to newsletter
      // saves concent in localstorage adds the user to sendgrid contacts
      // sets the consent to false
      // in future add a way to opt in after user registers.
      const newsletterConcent =
        window.localStorage.getItem('newsletterConsent');
      if (newsletterConcent === 'true') {
        console.log('testssss');
        addContact(loggedInUser);
        window.localStorage.setItem('newsletterConsent', 'false');
      }
    }
  }, [loggedInUser]);

  // Updates user to have free premium trial
  const handleTrialClick = () => {
    let currentUser = { ...loggedInUser };
    currentUser = {
      ...currentUser,
      isPremium: {
        trialMembership: true,
        trialExp: trialExpDate.toLocaleDateString('en-US'),
        isCurrent: true,
      },
    };
    updateUser({ currentUser }).then((res) => {
      if (res.status === 200) {
        setPremiumModalOpen(false);
        setUser(currentUser);
        toast.success(
          'Successfully upgraded to premium, enjoy your free trial.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    });
  };

  // Updates user after first time logging in
  const handleFirstLoginClick = () => {
    let currentUser = { ...loggedInUser };
    currentUser = {
      ...currentUser,
      firstLogin: false,
    };
    updateUser({ currentUser }).then((res) => {
      if (res.status === 200) {
        setUser(currentUser);
        toast.success(
          'Thank you for being a Fly Fish Finder member, we hope you enjoy your experience.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    });
  };

  // Removes Free trial from user
  const handleRemoveTrial = () => {
    let currentUser = { ...loggedInUser };

    currentUser = {
      ...currentUser,
      isPremium: {
        trialMembership: true,
        trialExp: currentUser.isPremium.trialExpDate,
        isCurrent: false,
      },
    };
    setUser(currentUser);
    updateUser({ currentUser }).then((res) => {
      if (res.status === 200) {
        setExpiredModalOpen(true);
      }
    });
  };

  const handleCancelClick = () => {
    setExpiredModalOpen(false);
  };

  // Gets user content bases on premium status
  const getUserContent = () => {
    // If user is loading
    if (user === null) return;
    // If user is premium show top spots
    if (user.isPremium.isCurrent) return <TopSpotsCard />;
    // If user is NOT premium show signup card
    return (
      <PremiumSignUpCard
        currentUser={loggedInUser}
        setPremiumModalOpen={setPremiumModalOpen}
      />
    );
  };

  return (
    <section className='viewContainer fade-in'>
      <div className='flex-grow py-12 px-3 md:px-10'>
        {/* User greeting */}

        <div className='flex justify-between'>
          <div>
            <h4 className='text-sm font-bold text-blueTertiary'>
              Hi {loggedInUser !== null && loggedInUser.firstName}{' '}
              {loggedInUser !== null && loggedInUser.lastName},
            </h4>
            <h1 className='text-4xl font-bold text-blueTertiary'>
              Welcome to your Dashboard!
            </h1>
            {setupProfile ? null : (
              <Alert text='finish setting up your profile' />
            )}
          </div>
          <div></div>
        </div>
        <div>
          {/* Stat Cards / FIRST ROW START*/}
          <div className='grid gap-4 md:gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-10'>
            {statData &&
              statData.map((stat) => <StatCard key={stat.title} stat={stat} />)}
          </div>
          {/* SECOND ROW START */}
          <div className='grid grid-cols-3 gap-4'>
            {getUserContent()}
            {/* TOP USERS */}
            <TopUsersCard
              isPremium={
                loggedInUser !== null && loggedInUser.isPremium.isCurrent
              }
            />
            {/* <Newsletter /> */}
          </div>
        </div>
      </div>
      {/* Premium modal */}
      <PremiumModal
        trialExpDate={trialExpDate}
        premiumModalOpen={premiumModalOpen}
        setPremiumModalOpen={setPremiumModalOpen}
        handleTrialClick={handleTrialClick}
      />
      {/* Expiration modal */}
      <PremiumExpirationModal
        expiredModalOpen={expiredModalOpen}
        setExpiredModalOpen={setExpiredModalOpen}
        handleCancelClick={handleCancelClick}
      />
      {/* First login modal */}
      <FirstLoginModal
        modalOpen={user && user.firstLogin}
        handleOkClick={handleFirstLoginClick}
      />
    </section>
  );
};

export default DashboardLanding;

export const courses = [
  {
    id: 0,
    url: 'fish-streamers',
    name: 'Fish Streamers Like A Pro',
    difficulty: 'Beginner',
    purchaseUrl: 'https://buy.stripe.com/8wM8zd2wSdEvfzG3cf',
    // test link
    // purchaseUrl: 'https://buy.stripe.com/test_fZe9Ck3gneKL69G8wy',
    about:
      'Fly Fish Finder is pleased to present you with a course on streamer fishing. Fish Streamers Like a Pro offers streamer fishing techniques under the guise of fish ecology to give anglers a full view of the necessary tactics to employ when fishing streamers. Beginners and experienced anglers alike will be able to learn about fishing different bodies of water, necessary streamer patterns, casting and stripping techniques, and the predator-prey interaction of trout and baitfish that will allow you to catch more fish and consistently. Our course is presented visually by experienced anglers and authored by a biologist/angler to provide an engaging and meaningful experience for those taking the course. By integrating fish ecology into this course, we believe you will be able to “get into the mind of a fish” and anticipate not only where they will be and why, but also how to imitate their prey based on water conditions, time of day, season, and apparent fish behavior. Join us and learn how to fish streamers like a pro.',
    coursePreviewPic:
      'https://res.cloudinary.com/dauxj5oo3/image/upload/v1642380047/Fish_Streamers_like_a_Pro_10_1_ss6jk0.png',
    heroImage: '',
    sections: [
      // Section 1
      {
        title: 'Introduction to Trout',
        content: `
        <p>  Trout are a fascinating fish closely related to salmon and charr. All three of these types of fish fall under the name “salmonid”. Since these fish are all closely related, they exhibit similar traits and behaviors that an angler can translate between species to gain an overarching understanding of how to catch more fish. Trout lifespans can be wide-ranging and can depend on geographic location and species, but many populations have relatively short lifespans and, as such, trout and other salmonids mature very quickly. Generally, trout live somewhere between 5 and 10 years, but trout have been recorded living considerably longer. Trout are resilient and can occupy a wide variety of habitats including lakes, streams, and rivers. Because of this fact they are also able to eat a wide variety of food including, as all anglers know, insects. However, trout diets also include a variety of fish and invertebrates with some species being more inclined to eat these types of meals than others. Since macro prey exhibit more higher level and complex behavior than insects, understanding how these prey items act, especially under predation pressure, can help you get inside the mind of the prey and more effectively mimic it when fishing those types of flies. Although specific species of trout have behavior unique to them, this course will also help you get inside the mind of a general trout which will lead you to catching more fish. If you can master the understanding of how a trout behaves then you can understand why it does what it does, and try to predict these processes while fishing.
</p><br/>
        <p>         While the general behavior of trout and charr is relatively universal among the big 4 species you might come across (rainbows, browns, brook trout, and cutthroat), it is important to note that there can be some differences between species. Most notably, brown trout are typically very aggressive and can be quite resilient to disturbance. Brown trout are not content with a diet consisting mostly of aquatic insects, they want real MEAT. Outside of insects, browns will feed on anything from small baitfish up to mice. This can make targeting browns fun by throwing flies that mimic food that a rainbow or brookie in the vicinity may be less inclined to chase because it is less aggressive than the browns in the area. Since browns are often more piscivorous than their counterparts they may also be inclined to eat smaller trout individuals. Flies that mimic trout are actually an option when fly fishing for trout. Cutthroat, rainbow, and brook trout of course eat fish as well but are far less aggressive and piscivorous than browns.
</p><br/>
        <p>         As you may know trout also eat fish eggs. What you may not know is that trout will eat eggs of both their species and other species. Eggs are a protein packed meal for trout and they have no-problem eating the eggs of their counterparts. This is thought to also be a process through which trout can reduce the competition for their own offspring giving them a better chance for survival which, in turn, increases that individual trout’s fitness. Since trout are driven by reproduction, it is to their advantage to have as many offspring survive to adulthood as possible so they may pass on their genetics. Trout may even eat their own fry if they find them, essentially putting those nutrient resources back into growth and future reproduction. This is a two-fold situation for trout because having as many immediate offspring survive to reproduce secures a trout’s ability to pass on its genes to another generation. However, reproduction is energy costly and securing the nutrients to continue to grow and reproduce again can be just as advantageous, regardless of how the nutrients are acquired.</p><br/>
        
        `,
        video: null,
        images: [
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290607/static-site-assets/Streamer_Course/Optimized-fish_in_net_c8m2i4.jpg',
            caption: 'Enter your caption here',
          },
        ],
        questions: [
          {
            question:
              'Of the four main trout species which is considered a charr?',
            options: [
              { title: 'Rainbow', isCorrect: false },
              { title: 'Brook', isCorrect: true },
              { title: 'Brown', isCorrect: false },
              { title: 'Cutthroat', isCorrect: false },
            ],
          },
          {
            question: 'What is one of the main drivers of trout behavior? ',
            options: [
              { title: 'Reproduction', isCorrect: true },
              { title: 'Growth', isCorrect: false },
              { title: 'Gaining territory', isCorrect: false },
              { title: 'All of the above', isCorrect: false },
            ],
          },
        ],
      },
      // Section 2
      {
        title: 'Rod Selection & Fly Lines',
        content: `<p>When it comes to streamers, the size of the rod will differ. Most people have a 5 weight as their standard trout rod. 5 weights can work well with smaller streamers (think classic wooly bugger) but as streamer size increases, so too must the size of the rod because it needs to be big enough to cast and turn that fly over. Since streamer casting is not quite as delicate as something like dry fly casting, it is easier to go with a bigger rod. Depending on the size of the fish and flies, a good streamer rod when fishing for trout is typically between a 6 and 7 weight. This will give you versatility in choosing flies to throw because they will be able to handle small flies with ease and handle big flies comfortably. Some streamer rods will even go up to an 8 weight and these are often used to target large steelhead and salmon. Another point to keep in mind is that larger fish require larger rods, not just simply because the rod needs to be able to handle larger fish but also because in order to release fish nice and healthy, we want to minimize the length of the fight and larger rods can put more pressure and fish and tire them out quicker. A good rule of thumb is to figure out the standard size of the fish you are targeting, the size of the flies needed to catch them, and adjust the rod size accordingly. 
</p><br/>`,
        video: 'https://youtu.be/3EwXV-QgDeo',
        images: null,
        questions: [
          {
            question:
              'Why is it important to have a larger rod choice when streamer fishing?',
            options: [
              { title: 'You are fishing bigger water', isCorrect: false },
              {
                title: 'You catch larger fish and need a larger rod',
                isCorrect: false,
              },
              {
                title: 'It is easier to turn over larger flies',
                isCorrect: true,
              },
              {
                title: 'You are using heavier line for larger flies',
                isCorrect: false,
              },
            ],
          },
        ],
      },
      // Section 3
      {
        title: 'Knots, Leaders & Tippet',
        content: `<p></p><br/>`,
        video: 'https://youtu.be/5mHaRdI0VfE',
        images: null,
        questions: [
          {
            question: 'How long should your streamer leader be?',
            options: [
              { title: '7 FT', isCorrect: false },
              { title: '8.5 FT', isCorrect: false },
              { title: '9 FT', isCorrect: false },
              { title: '7.5 FT', isCorrect: true },
            ],
          },
        ],
      },
      // Section 4
      {
        title: 'Loops',
        content: `<p></p><br/>`,
        video: 'https://youtu.be/tX2kiShtMUM',
        images: null,
        questions: [
          {
            question:
              'Why should you use a loop knot for trying streamers to a leader?',
            options: [
              {
                title: 'It acts as a swivel to give your fly more action',
                isCorrect: true,
              },
              {
                title: 'It is a stronger knot for big fish',
                isCorrect: false,
              },
              { title: 'It is easy to tie', isCorrect: false },
              {
                title: 'You can connect multiple flies to a loop',
                isCorrect: false,
              },
            ],
          },
        ],
      },
      // Section 5
      {
        title: 'Reading Water',
        content: `
        <p>The fundamental root of all animal behavior is having the ability to minimize cost while maximizing gain. Trout are no different and understanding how they can achieve this “goal” will help any angler catch more fish. Trout are effective and powerful predators, but at their core, regardless of the body of water they occupy, trout are looking to minimize energy output and maximize energy input. This is especially the case on rivers where water is moving constantly in one direction. It may not be obvious to all anglers that trout are all facing upstream allowing the river to bring their meal, oxygenate their kills, and simply so they can hold a stationary position. As such, a large percentage of a river is not occupied by any fish creating a small range for anglers to hone in on. It is important to understand that a trout is going to find the niche spots between slower and faster moving water where it can save energy in the slower water while the faster, adjacent water consistently brings meals. No one wants to fish empty water, and this course will help you avoid doing so.
</p><br/>
        <p>         To figure out where these niche spots might be, think about an example called the “conveyor belt situation”. Imagine you are in a room where the floor is made of a treadmill track. Between, and among each track are conveyor belts carrying various food items. The tracks are moving at all different speeds and some move in different directions. The conveyor belts are also moving at different speeds and in different directions, with some even spinning in circles (imagine an eddy on a river). There are some very enticing options going by but you do not want to spend too much energy trying to get them. It is up to you to find the area in this room where the track is moving the slowest and the adjacent conveyor belt is moving the fastest. This is exactly what a river looks like. Throughout a river, various structures change the speed of the current as it flows. In this example, the treadmill and conveyor belts represent different currents and seams. Seams are where two currents meet and this is where you can have some of your best fishing because the fast current adjacent to a slower current is like a super-highway of food for trout. 
</p><br/>
        <p>         Many anglers often will focus their attention behind a rock because the water is slowed or disrupted in some way by that type of structure. This is all well and good, and plenty of fish hold BEHIND rocks but a trout may very well be found in FRONT of a rock and there are plenty of submerged rocks and boulders that create the same phenomenon subsurface. This again brings up the idea of actually reading the water. Find that bug lane where it appears everything is being moved through and see if it matches up in some way with a seam. Remember the conveyor belt and decide where you would most like to be.
</p><br/>
        <p>         Once you can identify a seam try to observe where everything at the surface is being funneled. That is where the water is moving everything that is dead drifting, and this is where you can focus your attention. Seams are tricky to spot because at first glance the whole river looks like it is moving at the same speed. However, there are some tricks that can help you to identify a seam.
</p><br/>
<ol>
<li>1. Simply scan the water and look for subtle differences in the angle of water motion/the speed of water moving. Finding water moving at two different angles is a sure sign that two currents are coming together to form a seam.
</li>
<li>2. See if you can spot items dead drifting at the surface moving at different speeds. This is not a fool-proof solution because often dead drifting items are shuttled to the same place but it can work if you are having difficulty identifying a seam.
</li>
<li>3. Simply trust your gut. If you think you see a slower moving current adjacent to a faster moving current then you are probably looking at a seam and can put some focus on it.
</li>
</ol>
<br/>
        <p>        <span className='!font-semibold !text-xlg text-black'>WHY</span>  is the fish behavior important here? As noted before, fish are going to hangout where they can maximize input and minimize output which points them in the direction of seams and structure. Trout generic behavior is going to steer them to these types of areas but there are a multitude of other behavioral factors detailed below that influence how, when, and where you might catch trout.
</p><br/>
        <p>         Seams can be noted regardless of whether you are fishing big or small water. Small streams are fun because you must be technical with how you cast and present flies, but the general rules of reading water still apply. Big and wide rivers can be intimidating because at first glance none of the water looks fishy. But big rivers can be broken down into small sections from bank to bank. Unless heavily dammed, rivers are typically not completely uniform from bank to bank. So, you can apply concepts of reading water to each piece of river from one side to the other, simply by identifying the sections that distinguish themselves.
</p><br/>
        `,
        video: 'https://youtu.be/kc8PL3wZbqU',
        images: [
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1642339536/fish_diagram_y3lfnq.png',
            caption: 'This diagram displays a common scenario you may encounter and how fish settle into their various positions',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290524/static-site-assets/Streamer_Course/Optimized-DSC07591_sd79re.jpg',
            caption: 'Enter your caption here',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290534/static-site-assets/Streamer_Course/Optimized-DSC07592_wujr0t.jpg',
            caption: 'Enter your caption here',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290540/static-site-assets/Streamer_Course/Optimized-DSC07593_hgdbq6.jpg',
            caption: 'Enter your caption here',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290570/static-site-assets/Streamer_Course/Optimized-DSC07677_urqlq7.jpg',
            caption: 'Enter your caption here',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290582/static-site-assets/Streamer_Course/Optimized-DSC07678_mc5sca.jpg',
            caption: 'Enter your caption here',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290591/static-site-assets/Streamer_Course/Optimized-DSC07707_abhi0o.jpg',
            caption: 'Enter your caption here',
          },
        ],
        questions: [],
      },
      // Section 6
      {
        title: 'Still Water Differences',
        content: `<p>Although the root cause of trout behavior remains the same in still water environments, trout must behave slightly differently to capture food. First, food is not being brought to trout on a conveyor belt like it is in moving water so trout must take on a more proactive approach. In these environments, trout will rely more heavily on sound, sight, hearing, and their lateral line. The lateral line is a series of sensory organs running along the lateral side of fish on both sides. The lateral line is able to detect vibrations and movement which give fish a hypersensitive understanding of their environment. This is especially important in predator-prey interactions where both sides are fish because each has the ability to detect the other through their lateral line. Since fish rely heavily on their senses in still water, it is important that flies cast on lakes contain enough movement while maintaining the correct profile so that trout will actively seek them out.
</p><br/>
<p>         Reading the water is more difficult in still water but it is just as important. Trout living in still water still need structure and cover, and their diet is often similar to trout living in rivers. Sometimes trout can be found in the pelagic (open water) section of a still body of water, but when fish are in these areas, they are harder to pin down and you could spend all day trying to chase pelagic fish. Instead focus on areas that promote survival of trout prey. Trout still eat insects in still water so areas with mud, rocks, and weeds are great spots for insects to spawn. Additionally, downed trees and logs are good spots to find insects, but often even better spots to find small baitfish that use the area for cover. You must look closely and attentively at a still water environment to correctly read the water but once you find these areas it is to your advantage to focus your effort there. Lastly, these structural areas need to be deep enough so that it does not get so warm that the trout abandon the area. They also must be shallow enough to accurately present a fly. It may take some time walking along a still water shoreline but eventually these types of spots can be found.
</p><br/>

`,
        video: null,
        images: null,
        questions: [
          {
            question: 'What is most important to a trout’s habitat? ',
            options: [
              { title: 'Moving water', isCorrect: false },
              { title: 'Structure', isCorrect: true },
              { title: 'Shelter', isCorrect: false },
              { title: 'Stillwater', isCorrect: false },
            ],
          },
        ],
      },
      // Section 7
      {
        title: 'Seasonal Changes',
        content: `
        <p>One of the most notable changes in trout behavior is due to seasons. Trout like water between 50 and 60 degrees. Too cold and they get pretty lethargic, too warm and they do not want to expend the energy needed to acquire food. As one might imagine, temperatures on a river do not stay this constant year round. As such, it is important to know when trout are going to feed at different times of the year. During the spring, after the frost of the winter has been knocked off, fish can realistically be caught in the morning, evening, and throughout the day. Late afternoon into the evening during the spring can be prime time to find quality insect hatches while also staying warm yourself. Late morning into midday also works, especially on those colder spring days where a couple extra degrees in the middle of the day really serves to benefit the trout. A lot of their behavior can be anthropomorphized and a human is probably unlikely to want to eat right when they wake up when it is freezing cold outside, but once they warm up and have a little coffee, eating seems a lot more attractive.</p><br/>

        <p>As the year moves into the summer, the feeding hours become very condensed. It is tough to catch fish in the middle of a hot summer day and it is frankly very hard on the fish if you do catch them during this time. The heat sucks a lot of energy out of the fish and catching fish in the middle of a hot summer day exhausts the fish and greatly increases the risk of post-release mortality. Summer time is the epitome of the morning and evening hatch. Given that trout are cold-blooded and cannot regulate their own blood temperature, they are at the mercy of their environment. So one might imagine that throughout the day they would rather stay in the shade and/or an area where they can expend little energy. Additionally, warmer water holds less dissolved oxygen than colder water so the trout simply have less to breathe. As soon as the sun starts to go down in the evening rivers will transform and start to come to life. The evening affords trout their first opportunity for consistent feeding on a summer day which can make the evening hatch even better than the morning hatch. The trout recognize once day turns to night and start to give themselves away as they start to rise and sip bugs off the surface. The best time to fish during the summer is after it rains. Rain brings an influx of cool water that will reinvigorate trout and cause them to resume eating again for some time. The day or days after fresh rain can be quite productive provided the rain doesn’t blow out the river. However, if a river is blown by recent rain, streamer action can pickup because it provides an active flashy target for fish to recognize and attack. Simply using a nymph or dry fly in a river that has been blown out is often in vain because the fish cannot see well enough through the water column.</p><br/>
        <p>One trick to keep in mind is that the overly loud and “splashy” rises are often smaller trout. Across the animal kingdom, younger individuals of a species have greater metabolic needs than their older, larger counterparts. Smaller individuals also have a drive to grow as quickly as possible. If looking to target larger fish, look and listen for the more subtle rises. The older, larger fish have greater finesse when it comes to rising to insects because their competition is lower and their needs are different than their smaller counterparts.  If you are a lover of dry fly fishing then summer is your time to shine.</p><br/>

        <p>As the summer rolls on and the temperature hit their peak you might run into some “dog days” of summer. Not to worry. The trout are still there and they still have to eat at some point. If you are willing to don a headlamp and get up early and/or stay up late, then some extreme times might net you a lot of fish. Fish can be caught consistently long before sunup in the morning, and at/after sundown in the evening. These times have cool temperatures that will promote feeding behavior.  </p><br/>
        <p>Fall is one of the best and most beautiful times of the year to fish. As the temperatures drop to numbers comparable to spring, or even lower, then fishing from morning until evening becomes very attractive. You can follow general spring hours in the fall but fishing all day (if time allows) allows you to hit all your favorite spots and who doesn’t want to be on the water all day anyways? The bottom line is that the fish are active throughout the day in the fall, simply because of the fact that they can be. Fall temperatures consistently promote active feeding behavior and fish can be caught dawn to dusk. If you are stickler for the dry fly fishing of summer, then don’t sleep on the nymph action you might be able to get in the fall.</p><br/>
        <p>Winter fishing encompasses the days in which you may question your reasoning for ever picking up this sport. The days where you may constantly be chipping ice out of your guides (there are ways to combat this). Well these days may be miserable at times, but depending on where you are located there are definitely still fish to be caught. As long as the water temperatures don’t dip too low then the fish will still bite with relative consistency. Consider getting yourself a $20 portable thermometer (like Fish Pond’s thermometer) and this will help you understand how lethargic the fish may be. Fishing when the water temperatures fall into the 40s is still quite productive. After that point fish can really get lethargic. Fish in general are able to slow their metabolism down greatly so as to conserve energy. Once they do that, they are not going to move around or do much at all. Yes, that includes eating. However, as long as those temperatures don’t dip too low, you will be able to find plenty of fish that haven’t entered this “trance”.</p><br/>
        <p>We think it is worth mentioning, since this course is primarily about streamer fishing, that due to the extreme nature of winters, especially in the northern reaches of the world, streamer fishing can be challenging. We would not hestitate to recommend giving it a shot if nymphs are not hitting, but remember to 'sink them low and move them slow'. Baitfish will be moving slower, as well as the trout this time of year so landing the fly quietly and dragging it near the bottom will be your best bet to land a fish.
        `,
        video: null,
        images: [
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290508/static-site-assets/Streamer_Course/Optimized-brown_side_sbbalo.jpg',
            caption: 'Enter your caption here',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290502/static-site-assets/Streamer_Course/Optimized-brown_on_streamer_kchmmb.jpg',
            caption: 'Enter your caption here',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290492/static-site-assets/Streamer_Course/Optimized-brown_behind_net_enmhed.jpg',
            caption: 'Enter your caption here',
          },
        ],
        questions: [],
      },
      // Section 8
      {
        title: 'Choosing Streamers and Fly Retrieval',
        content: `
        <p>There are three important points of note when fishing a streamer. 1. Presentation. 2. Color. 3. Profile. The profile the fly creates is somewhat of an umbrella term for the first two points. While fish may sometimes follow a fly for a ways out of their lie, the event of a predator-prey interaction is typically relatively short. Predator-prey interactions can be broken into detection, assessment/evaluation, pursuit, and seizure (Whitford et al. 2018). It is important to stay consistent because the assessment stage can last a long time, but once a trout enters the pursuit stage there is a short period to fully convince the fish that your fly is indeed food. Flies obviously do not perform like bait. And, they are also not like lures which typically mimic something more precisely. However, this is the beauty of this sport in that you are tasked with making something made out of feathers and fur, ultimately look like food to fish. Predators like trout figure if something does not look enough like food, then they can find a safer meal later. Additionally, fish like trout are not necessarily at the top of the food chain in their respective ecosystem and can fall victim to birds or even some land mammals. As such, they are very diligent in ensuring their survival, so spooking them by presenting a fly incorrectly, or presenting the wrong fly over and over can ruin a stretch of a run for you temporarily.  </p><br/>

        <p>Here we outline the basics of streamer colors and their general effectiveness based upon the current conditions you are facing. As general advice, we recommend fishing a particular size and color for 15 minutes or so before swapping.  Start by reducing size and changing colors when you do decide to make a change.  Follow a general pattern of starting light and moving darker, especially in those warmer seasons: Whites > Tan > Light Olive > Dark Olive/Purples/Oranges > Browns/Rust Reds/Oranges > Black</p>
        <br/>
        <!-- Baitfish Table -->
          <p class="text-gray-500 text-lg text-center font-bold m-5">Recommended Flies</p>
          <table class="rounded-t-lg m-5 w-full mx-auto text-gray-100 bg-gradient-to-l from-blue-500 to-blue-800">
            <tr class="text-left border-b-2 border-blue-500">
              <th class="px-4 py-3">Organism</th>
              <th class="px-4 py-3">Sculpins</th>
              <th class="px-4 py-3">Leeches</th>
              <th class="px-4 py-3">Crayfish</th>
              <th class="px-4 py-3">Baitfish</th>
            </tr>
            <tr class="border-b border-blue-500">
              <td class="px-4 py-3">Patterns</td>
              <td class="px-4 py-3">Madison Sculpin</td>
              <td class="px-4 py-3">Schlapen Bugger</td>
              <td class="px-4 py-3">Craw bugger</td>
              <td class="px-4 py-3">Brush head minnow</td>
            </tr>    
          <!-- each row -->
            <tr class="border-b border-blue-500">
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3">Rabbits Foot</td>
              <td class="px-4 py-3">Slim Shady</td>
              <td class="px-4 py-3">Creeky Crawley</td>
              <td class="px-4 py-3">Cooter Brown</td>
            </tr>    
          <!-- each row -->
            <tr class="border-b border-blue-500">
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3">Sculperino</td>
              <td class="px-4 py-3">Wooly Bugger</td>
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3">Disco Minnow</td>
            </tr>   
            <tr class="border-b border-blue-500">
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3">Bearded Dragon</td>
            </tr>    
            <tr class="border-b border-blue-500">
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3"></td>
              <td class="px-4 py-3">Angel Hair Minnow</td>
            </tr>    
          <!-- each row -->
         
          </table>
           <p class='text-sm text-brandprimary mt-6'>Blue Line Co  - Refer to our videos on how to fish and strip some of these flies. As a rule of thumb, on sunny/bluebird days think bright and flashier.  On overcast or precipitating days, think dull colors but thicker flies that make some ‘noise’.</p><br/>
        <br/>
 <!-- BaitFish images -->
        <div class="grid sm:grid-cols-3 gap-4 grid-cols-1">
        <div>
          <div class="bg-gray-300">
          <img src='https://res.cloudinary.com/dauxj5oo3/image/upload/v1631581744/static-site-assets/Streamer_Course/Baitfish/optimized-DSC07675.png' alt='image' />
          </div>
          <h3 class='text-sm text-brandprimary mt-6'>The parr, pre-smolt, and smolt stages (A-C respectively) of sea-run brown trout Salmo trutta. Trout smolt are food for sources for other adult trout.</h3>
        </div>
        
        <div>
          <div class="bg-gray-300">
          <img src='https://res.cloudinary.com/dauxj5oo3/image/upload/v1631581921/static-site-assets/Streamer_Course/Baitfish/rabbits_foot.jpg' alt='image' />
          <img src='https://res.cloudinary.com/dauxj5oo3/image/upload/v1631581917/static-site-assets/Streamer_Course/Baitfish/buckhead_streamer.jpg' alt='image' />
          </div>
          <h3 class='text-sm text-brandprimary mt-6'>Different forms of shiner (Cyprinidae). Shiner are minnows abundant in different lakes and rivers and can be a food sources for adult trout.</h3>
        </div>
        <div>
          <div class="bg-gray-300">
          <img src='https://res.cloudinary.com/dauxj5oo3/image/upload/v1631581767/static-site-assets/Streamer_Course/Baitfish/sculperino.jpg' alt='image' />
          <img src='https://res.cloudinary.com/dauxj5oo3/image/upload/v1631581770/static-site-assets/Streamer_Course/Baitfish/white_sculperino.jpg' alt='image' />
          <img src='https://res.cloudinary.com/dauxj5oo3/image/upload/v1631581774/static-site-assets/Streamer_Course/Baitfish/sculperino1.jpg' alt='image' />
          </div>
          <h3 class='text-sm text-brandprimary mt-6'>Sculpin (Cottoidae) are a family of fish that dwell on the bottom of lakes and rivers. There are a large amount of different sculpin species and there are even saltwater sculpin. Sculpin can be a large source of protein for trout but are at an advantage in that they are very well camouflaged. </h3>
        </div>
       
      </div>
        
        <br/>
       
        <p>One common misconception is that you can only catch large fish on large flies. While it can be true that large flies will attract large fish, small flies can do just the trick, especially if they match the profile and color fish are biting on that particular day. Many young baitfish are quite small and most do not make it to adulthood. In ecology the term “Survivorship Curve” refers to the survivorship strategy a particular species employs from birth to adulthood. There are three types of curves; 1. Low death rate of young individuals with many old individuals in the population. 2. Steady death rate across all ages. 3. High death rate at a young age with few living into adulthood/old age. Baitfish fall under category three and this is often due to voracious predators like trout. Baitfish have a large amount of young so that the amount that actually make it to adulthood is evened out. The point here is that size does not always matter. Trout are going to feed on what they can fit in their mouth whether it be the smallest nymph or the largest sculpin in the river. Understanding this fact can lead you to catching many fish both large and small on the same size flies.</p><br/>
        <p>When fishing a streamer it is important to understand that baitfish have to enact erratic and unpredictable movements to avoid predators. Trout are much larger and more powerful than small baitfish and could easily catch them in a straight line race. This is why varied retrieval is the name of the game when fishing a streamer if you are not simply swinging the fly. Trout may prefer one retrieval over another on particular days and it is up to the angler to figure out the sweet spot. However, it can be advantageous to start off as random as possible with your retrieval and narrow it down to more uniform strips with each subsequent cast through a run. Trout may sometimes ambush unsuspecting prey, but often prey may give themselves up because they grow worried that they have been detected by trout. This promotes erratic escape behavior to which they force trout to react. The better you can simulate this the more fish you should catch.</p><br/>
        <p>Trout will also eat baitfish whether they are dead or alive. A live specimen is likely going to employ a quick erratic escape strategy but a dead specimen is completely at the mercy of the current. Different trout prey will use different strategies as well, and the beauty of streamer fishing is that one streamer can represent various types of trout prey. Being able to mimic different trout prey species through different stripping techniques will allow you to change what you are presenting to a fish without changing the fly. Fly Fish Finder has a community of anglers that allows for reaching out to get a sense of what flies and streamers are hitting on various water. Connecting with local anglers via the platform may help you narrow your patterns in the fly box. </p></br>

        <ol>
        <li>1.     Demersal (bottom dwelling) species such as sculpin and crawfish/crustaceans will often rely on camouflage and crypsis (remaining motionless) to avoid detection. However, if a trout gets too close these prey will often attempt to flee even if the trout has not actually detected them; The prey doesn’t necessarily know if has been detected unless the trout attacks, at which point the prey may have no chance of escape. These type of prey are poor distance swimmers and will attempt a rapid short escape route where they can resettle into the sediment and hide from the trout again. Sculpin especially, have a powerful tail and its initial couple of kicks propel it quite far. Crustaceans are not built for swimming but will erratically make a series of “jumps” in an attempt to swim away.  
</li><br/>
        <li>2.     Leeches are another popular food item for trout. Leeches are high in protein and are easy to capture. Egg sucking leeches are especially attractive to trout because it is like a two for one meal at a fast food restaurant. Leeches swim poorly and often use undulations to try to move through the water. However, leeches are at the mercy of the current for the most part. Slowing down how you move a fly that can represent a leech will shift it from representing a fish and give you the presentation you desire.</li><br/>

        <li>3.     Various types of minnows also inhabit trout streams; You can even throw trout fry into this category given that trout will eat the young of other trout. These minnow type prey are going to be the best baitfish swimmers in the river. They will be able to employ the most dynamic movements and do not necessarily rely on camouflage like some of the aforementioned species.  These fish may move sharply for a period of time then slowly for a period of time to mix up their escape strategy. Since they rely more on their maneuverability to escape they will have the most lengthy erratic escape attempts.</li><br/>
        </ol></br>

        <p>It is important to note that if you vary a stripping technique through a run then you may change the species you are representing. Changing a technique according to what a particular run allows is going to land you more fish. If you attack each cast with a game plan for what you would like to present to the fish then you will be in prime position to succeed.</p></br>
        
        `,
        video: 'https://youtu.be/lzKrZc9iSO4',
        images: [
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290517/static-site-assets/Streamer_Course/Optimized-Buckhead_Streamer_2_xzxg7r.jpg',
            caption: 'Buckhead Streamer',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290615/static-site-assets/Streamer_Course/Optimized-Madison_Sculpin_owibjm.jpg',
            caption: 'Madison Sculpin',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290623/static-site-assets/Streamer_Course/Optimized-Rabbits_Foot_z21ogm.jpg',
            caption: 'Rabbits Foot',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290630/static-site-assets/Streamer_Course/Optimized-Schlapen_Bugger_1_weoj5l.jpg',
            caption: 'Schlapen Bugger',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290638/static-site-assets/Streamer_Course/Optimized-Schlapen_Bugger_fs52qk.jpg',
            caption: 'Schlapen Bugger',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290649/static-site-assets/Streamer_Course/Optimized-Sculperino_1_muirig.jpg',
            caption: 'Sculperino',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290656/static-site-assets/Streamer_Course/Optimized-Sculperino_ijbpjt.jpg',
            caption: 'Sculperino',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290663/static-site-assets/Streamer_Course/Optimized-White_Sculperino_t0tntq.jpg',
            caption: 'White Sculperino',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290561/static-site-assets/Streamer_Course/Optimized-DSC07675_sovczn.jpg',
            caption: '',
          },
          {
            url: 'https://res.cloudinary.com/dauxj5oo3/image/upload/v1631290599/static-site-assets/Streamer_Course/Optimized-DSC08764_papov6.jpg',
            caption: '',
          },
        ],
        questions: [
          {
            question:
              'What is the first tactic to employ when retrieving streamers (if not swinging them)?',
            options: [
              { title: 'Steady and smooth', isCorrect: false },
              { title: 'Erratic', isCorrect: true },
              { title: 'Extremely fast ', isCorrect: false },
            ],
          },
          {
            question:
              'What is the most prolific retrieve method on streamers (one that we often use as well)?',
            options: [
              { title: 'Strip Strip Strip ', isCorrect: false },
              { title: 'Strip and Pause evenly', isCorrect: false },
              { title: 'Strip Strip Pause', isCorrect: true },
            ],
          },
        ],
      },
    ],
    recommendedVideos: [
      {
        title: 'Casting Heavy Streamers',
        url: 'https://youtu.be/Nab0EzZUDPE',
      },
      {
        title: 'Strip Strip Baby Brown Retrieve & Swim Action',
        url: 'https://youtu.be/kh1YpTVI3N8',
      },
      {
        title: 'Stripping Line',
        url: 'https://youtu.be/arztIX_0W04',
      },
      {
        title: 'Constant Strip Slow-mo Whitefish Imitation',
        url: 'https://youtu.be/w0KkRbAmRxA',
      },
      {
        title: 'Constant Strip Slow-mo Baby Brown Imitation',
        url: 'https://youtu.be/eWJyjfln3H8',
      },
      {
        title: 'Bugger Swimming SWING Method',
        url: 'https://youtu.be/D0Na0tTn2kY',
      },
      {
        title: 'Bugger Strip Method',
        url: 'https://youtu.be/ybLZSYZQb_E',
      },
    ],
  },
];

export const sliderSettings = {
  dots: true,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

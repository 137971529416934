import React from 'react';

const CoursesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-6 w-6'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M13 10V3L4 14h7v7l9-11h-7z'
      />
    </svg>
  );
};

export default CoursesIcon;

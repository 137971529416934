import { useState, useEffect } from 'react';
import axios from 'axios';

/**
 *
 * @returns Gets User by ID
 */

export default function useCurrentUser() {
  const userId = window.sessionStorage.getItem('userId');
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (userId !== null) {
      axios
        .get(`/.netlify/functions/getUserByAuthId?id=${userId}`)
        .then((res) => {
          // console.log(res.data)
          setUser(res.data);
        })
        .catch((err) => {
          console.error('ERROR GETTING USER', err);
        });
    }
  }, [userId]);

  return user;
}

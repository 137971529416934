import ArrowLeft from '../../icons/ArrowLeft';
import { withRouter } from 'react-router-dom';

const BackButton = ({ history }) => {
  return (
    <>
      {history.location.pathname !== '/dashboard-home' && (
        <button
          onClick={() => history.goBack()}
          className='h-8 w-8 rounded bg-brandPrimary text-white flex justify-center items-center'>
          <ArrowLeft />
        </button>
      )}
    </>
  );
};

export default withRouter(BackButton);

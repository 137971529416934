import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import logoBlack from '../../img/logos/logoBlack.svg';
import logoBlue from '../../img/logos/logoBlue.svg';

export default function Header() {
  let expanded = false;

  return (
    <div className='container mx-auto flex flex-wrap p-5 items-center md:bg-opacity-70 lg:bg-opacity-70 xl:bg-opacity-70 2xl:bg-opacity-70'>
      <Link
        to='/'
        className='flex title-font font-medium items-center text-gray-900 mb-4  md:ml-0  md:mb-0 logo'>
        <img src={logoBlack} alt='Logo' width={55} height={55} />
      </Link>
      {/* Mobile Nav */}
      <div className='mobileMenu'>
        <Menu isOpen={expanded} className='burger-menu'>
          <div className='w-full flex mb-4 opacity-100'>
            <Link to='/' className='ml-auto'>
              <img src={logoBlue} alt='Logo' width={55} height={55} />
            </Link>
          </div>
          <Link id='courses' className='menu-item' to='/courses'>
            Courses
          </Link>
          <a
            id='blog'
            className='menu-item'
            target='_blank'
            rel='noopener noreferrer'
            href='https://blog.flyfishfinder.com'>
            Blog
          </a>
          <Link
            id='fly-tying'
            className='text-brandPrimary duration-300  opacity-80 mr-5 hover:opacity-100'
            to='/fly-tying'>
            Fly Tying
          </Link>
          <Link id='about' className='menu-item' to='/about'>
            About
          </Link>
        </Menu>
      </div>

      {/* Main Nav */}
      <nav className='font-normal mx-auto flex flex-wrap items-center text-base justify-center flex  md:bg-opacity-70 lg:bg-opacity-70 xl:bg-opacity-70 2xl:bg-opacity-70 hidden md:block '>
        <Link
          to='/courses'
          className='text-brandPrimary duration-300 opacity-80 mr-5 hover:opacity-100'>
          Courses
        </Link>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://blog.flyfishfinder.com'
          className='text-brandPrimary duration-300  opacity-80 mr-5 hover:opacity-100'>
          Blog
        </a>
        <Link
          id='fly-tying'
          className='text-brandPrimary duration-300  opacity-80 mr-5 hover:opacity-100'
          to='/fly-tying'>
          Fly Tying
        </Link>
        <Link
          id='about'
          className='text-brandPrimary duration-300  opacity-80 mr-5 hover:opacity-100'
          to='/about'>
          About
        </Link>
      </nav>
    </div>
  );
}

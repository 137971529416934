import React from 'react';

const CustomInput = ({
  value,
  onChange,
  name,
  placeholder = '',
  autoComplete = '',
  type = 'text',
  wrapperClasses = '',
  label = '',
}) => {
  let component;
  switch (type) {
    case 'text':
      component = (
        <input
          type={type}
          className='form-control text-gray-900 w-full p-2 lg:rounded-lg md:rounded-lg border-2 rounded-lg'
          value={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          autoComplete={autoComplete}
        />
      );
      break;
    case 'textarea':
      component = (
        <textarea
          type={type}
          className='form-control text-gray-900 w-full p-2 lg:rounded-lg md:rounded-lg border-2 rounded-lg'
          value={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          rows='5'
        />
      );
      break;

    default:
      break;
  }
  return (
    <div className={wrapperClasses}>
      <p className='text-blueGray-500 mb-2 font-semibold text-sm'>{label}</p>
      {component}
    </div>
  );
};

export default CustomInput;

function TerrainControl() {
  this.onAdd = function (map) {
    this._map = map;
    this._btn = document.createElement('button');
    this._btn.type = 'button';
    this._btn['aria-label'] = 'Terrain Control'; 
    this._btn['title'] = 'Terrain Control';
    this._btn.style.backgroundImage = "url('https://icongr.am/material/terrain.svg?size=30color=currentColor')";
    this._btn.style.backgroundPosition = "center";
    this._btn.style.backgroundRepeat = "no-repeat";
    this._btn.style.borderRadius = "16px"

    let hasTerrain = false;

    this._btn.onclick = function () {
      if (!hasTerrain) {
        if (!map.getSource('mapbox-dem')) {
          map.addSource('mapbox-dem', {
            'type': 'raster-dem',
            'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
            'tileSize': 512,
            'maxzoom': 14
          });
        }
        map.addLayer({
          'id': 'sky',
          'type': 'sky',
          'paint': {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15
          }
        });
        map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
        map.flyTo({
          center: map.getCenter(),
          zoom: map.getZoom(),
          pitch: 75,
          speed: 0.01,
        })
        hasTerrain = true
        this.style.backgroundColor = "gold"
      }else{
        map.removeLayer("sky")
        map.setTerrain(null);
        hasTerrain = false;
        this.style.backgroundColor = "transparent"
        map.flyTo({
          center: map.getCenter(),
          zoom: map.getZoom(),
          pitch: 0,
          speed: 0.01,
        })
      }
    };

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container.appendChild(this._btn);
    return this._container;
  };

  this.onRemove = function () {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  };
}

export default TerrainControl
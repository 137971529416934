import React from 'react';
import Modal from 'react-modal';
import ArrowRight from '../../icons/ArrowRight';

/**
 * Modals for premium sign up, premium expiration and first login
 */

const modalStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // width: '75%',
    // transform: 'translate(-50%, -50%)',
  },
};

// Premium Trial Modal
export const PremiumModal = ({
  premiumModalOpen,
  setPremiumModalOpen,
  handleTrialClick,
  trialExpDate,
}) => (
  <Modal
    isOpen={premiumModalOpen}
    onRequestClose={() =>
      setPremiumModalOpen((premiumModalOpen) => !premiumModalOpen)
    }
    style={modalStyles}
    contentLabel='Example Modal'>
    <div className='flex flex-col justify-center items-center'>
      <h1 className='text-brandPrimary text-2xl font-semibold'>
        Please confirm you would like start your trial membership
      </h1>
      <p className='text-gray-500 text-md mt-3'>
        By continuing you will begin your free trial of Fly Fish Finder Premium.
        Your trial will end on <b>{trialExpDate.toLocaleDateString('en-US')}</b>
      </p>
      <div className='flex flex-wrap mt-5'>
        <button
          className='mr-3 mt-3 bg-brandPrimary hover:bg-brandPrimary hover:shadow-md px-5 py-2 text-xs font-medium tracking-wider text-white rounded-full transition ease-in duration-300'
          onClick={handleTrialClick}>
          Yes, I want premium
        </button>
        <button
          className='mt-3 bg-gray-50 hover:shadow-md px-5  py-2 text-xs font-medium tracking-wider text-brandPrimary rounded-full transition ease-in duration-300'
          onClick={() =>
            setPremiumModalOpen((premiumModalOpen) => !premiumModalOpen)
          }>
          No, I am going to wait
        </button>
      </div>
    </div>
  </Modal>
);

// Trial Expiration Modal
export const PremiumExpirationModal = ({
  expiredModalOpen,
  setExpiredModalOpen,
  handleCancelClick,
}) => (
  <Modal
    isOpen={expiredModalOpen}
    onRequestClose={() => handleCancelClick}
    style={modalStyles}
    contentLabel='Example Modal'>
    <div className='flex flex-col justify-center items-center'>
      <h1 className='text-brandPrimary text-2xl font-semibold'>
        Your trial membership has ended...
      </h1>
      <p className='text-gray-500 text-md mt-3'>
        Please purchase a subscription to continue enjoying all the premium
        benefits.
      </p>
      <div className='flex flex-wrap mt-5'>
        <a
          href='https://buy.stripe.com/test_bIY5m43gnfOPfKg7st'
          className='mr-3 mt-3 bg-brandPrimary hover:bg-brandPrimary hover:shadow-md px-5 py-2 text-xs font-medium tracking-wider text-white rounded-full transition ease-in duration-300'>
          Purchase Premium Yearly Plan
        </a>
        <a
          href='https://buy.stripe.com/test_cN2g0I2cjbyzbu0144'
          className='mr-3 mt-3 bg-brandPrimary hover:bg-brandPrimary hover:shadow-md px-5 py-2 text-xs font-medium tracking-wider text-white rounded-full transition ease-in duration-300'>
          Purchase Premium Monthly Plan
        </a>
        <button
          className='mt-3 bg-gray-50 hover:shadow-md px-5  py-2 text-xs font-medium tracking-wider text-brandPrimary rounded-full transition ease-in duration-300'
          onClick={() =>
            setExpiredModalOpen((expiredModalOpen) => !expiredModalOpen)
          }>
          No, I am going to wait
        </button>
      </div>
    </div>
  </Modal>
);
// First Login Modal
export const FirstLoginModal = ({ modalOpen, handleOkClick }) => (
  <Modal isOpen={modalOpen} style={modalStyles} contentLabel='Example Modal'>
    <div className='flex flex-col justify-center items-center'>
      <h1 className='text-brandPrimary text-2xl font-semibold text-center'>
        Welcome To Fly Fish Finder
      </h1>
      <p className='text-gray-500 text-md mt-3 text-center'>
        Thanks for registering with Fly Fish Finder. You will automatically be
        assigned a Free tier account. Please confirm your registration with the
        auto generated email that will be sent to you.
      </p>
      <div className='w-full md:w-4/6 mx-auto mt-8'>
        <div className='flex text-gray-500 mr-3'>
          <div className='w-1 mr-3 mt-1 text-brandPrimary'>
            <ArrowRight />
          </div>
          <p className='ml-3 '>
            Hit settings on the left sidebar and update your Angler Profile
          </p>
        </div>

        <div className='flex text-gray-500 mr-3'>
          <div className='w-1 mr-3 mt-1 text-brandPrimary'>
            <ArrowRight />
          </div>
          <p className='ml-3 '>Check out our available Courses</p>
        </div>

        <div className='flex text-gray-500 mr-3'>
          <div className='w-1 mr-3 mt-1 text-brandPrimary'>
            <ArrowRight />
          </div>
          <p className='ml-3 '>
            Upgrade to Premium from your Dashboard to gain full map access and future features
          </p>
        </div>
      </div>

      <div className='flex flex-wrap mt-5'>
        <button
          className='mt-3 bg-brandPrimary hover:shadow-md px-5  py-2 text-xs font-medium tracking-wider text-white rounded-full transition ease-in duration-300'
          onClick={handleOkClick}>
          Great, lets go!
        </button>
      </div>
    </div>
  </Modal>
);

import React from 'react';

const CardHeader = ({ title, icon, classNames, ...res }) => {
  return (
    <div className={`flex items-center text-brandPrimary ${classNames}`}>
      {icon}
      <p className='font-semibold ml-4 text-md font-semibold text-md'>
        {title}
      </p>
    </div>
  );
};

export default CardHeader;

import axios from "axios";

/**
 *  Checks if user exists in DB
 * @param {*} userInfo Information from Auth0
 */

// export default async function removeFromCloudinary(imageID) {
//   axios
//     .post(`/.netlify/functions/removeCloudinaryImage?imageId=${imageID}`)
//     .then((res) => {
//       console.log("DELETED");
//       return res;
//     })
//     .catch((err) => {
//       console.log("error " + err);
//     });
// }

export default function removeFromFirebase(image_url) {
  let deleteBody = {
    image_url,
  };

  if (typeof image_url != "string") {
    console.log("delete function run");

    axios
      .post(
        `https://us-central1-fly-fish-finder.cloudfunctions.net/deleteImage`,
        deleteBody
      )
      .then((res) => {
        console.log("Image deleted Successfully", res);
      })
      .catch(() => {
        console.log("Error deleting image");
      });
  }
}

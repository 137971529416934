import React, { Fragment } from 'react';
import ViewHeader from '../components/common/ViewHeader';
import UserCard from '../components/UserCard';
import useMembers from '../hooks/useMembers';
import Spinner from '../components/common/Spinner';

const Users = () => {
  const members = useMembers();

  return (
    <>
      {members === null ? (
        <Spinner />
      ) : (
        <section className='viewContainer fade-in'>
          <div className='ml-4'>
            <ViewHeader title='Anglers' />
          </div>

          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
            {members !== null &&
              members.map((user, i) => {
                // If the user does not want profile show then exit
                if (!user.showProfile) return <Fragment key={i}></Fragment>;
                // If the user does then show profile
                return <UserCard key={i} user={user} />;
              })}
          </div>
        </section>
      )}
    </>
  );
};

export default Users;

import comingSoon from '../../img/comingSoon.svg'
export const availableCourses = [
  {
    id: 0,
    url: 'fish-streamers',
    name: 'Fish Streamers Like A Pro',
    price: '$100.00',
    price2: '$50.00',
    difficulty: 'Beginner',
    purchaseUrl: 'https://buy.stripe.com/8wM8zd2wSdEvfzG3cf', // -> actual URL
    // purchaseUrl: 'https://buy.stripe.com/test_fZe9Ck3gneKL69G8wy', // -> test URL
    about:
      'Fly Fish Finder is pleased to present you with a course on streamer fishing. Fish Streamers Like a Pro offers streamer fishing techniques under the guise of fish ecology to give anglers a full view of the necessary tactics to employ when fishing streamers. Beginners and experienced anglers alike will be able to learn about fishing different bodies of water, necessary streamer patterns, casting and stripping techniques, and the predator-prey interaction of trout and baitfish that will allow you to catch more fish and consistently. Our course is presented visually by experienced anglers and authored by a biologist/angler to provide an engaging and meaningful experience for those taking the course. By integrating fish ecology into this course, we believe you will be able to “get into the mind of a fish” and anticipate not only where they will be and why, but also how to imitate their prey based on water conditions, time of day, season, and apparent fish behavior. Join us and learn how to fish streamers like a pro.',
    coursePreviewPic:
      'https://res.cloudinary.com/dauxj5oo3/image/upload/v1642380047/Fish_Streamers_like_a_Pro_10_1_ss6jk0.png',
  },
  {
    id: 1,
    url: 'FishStreamers',
    name: 'Casting Basics',
    difficulty: 'Beginner',
    about:
      'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.',
    coursePreviewPic: comingSoon,
  },
  {
    id: 2,
    url: 'FishStreamers',
    name: 'Advanced Fly-tying',
    difficulty: 'Advanced',
    about:
      'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.',
    coursePreviewPic: comingSoon,
  },
  {
    id: 3,
    url: 'FishStreamers',
    name: 'Tracking new land',
    difficulty: 'Advanced',
    about:
      'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.',
    coursePreviewPic: comingSoon,
  },
]
export const sliderSettings = {
  dots: true,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

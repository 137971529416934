import { useState, useEffect } from 'react';

const SingleQuestion = ({ question, currentIndex }) => {
  // stores the index of the selected answer
  const [correctAnswer, setCorrectAnswer] = useState(null);
  // Stores the users selected answer
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [userCanSelect, setUserCanSelect] = useState(true);

  useEffect(() => {
    return () => {
      setCorrectAnswer(null);
      setSelectedAnswer(null);
      setUserCanSelect(true);
    };
  }, []);

  const handleOptionClick = (selectedOption) => {
    setUserCanSelect(false);
    setSelectedAnswer(selectedOption);
  };

  const getOptionalClasses = (index) => {
    // For default state of questions
    if (selectedAnswer === null) return `border-gray-300 bg-white`;
    // Returns styles for when the user selects an option
    if (correctAnswer === index) {
      return `border-green-400 bg-green-400`;
    } else {
      return `border-red-400 bg-red-400`;
    }
  };
  return (
    // Outer question wrapper
    <div className='mb-5'>
      <h4 className='font-semibold mb-4'>
        <b>{currentIndex + 1}. </b>
        {question.question}
      </h4>
      <div>
        {question.options.map((option, index) => {
          // Set the correct answer in state
          if (correctAnswer === null && option.isCorrect == true) {
            setCorrectAnswer(index);
          }
          return (
            <div
              key={index}
              className={`rounded border flex items-center py-2 px-5 mb-2  cursor-pointer hover:shadow transition duration-300 ${getOptionalClasses(
                index
              )}`}
              onClick={userCanSelect ? () => handleOptionClick(index) : null}>
              <span
                className={
                  selectedAnswer === null ? `text-gray-600` : `text-white`
                }>
                {option.title}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SingleQuestion;

import MapboxGLButtonControl from './MapboxControl';

//TODO need to share layer visibility state with react?
const LayerControl = (map, layers, isPremium) => {
  const premium = !isPremium ? false : true;

  function mapHasLayer(layerId) {
    const layers = map.getStyle().layers;
    const ids = layers.reduce((i, l) => [...i, l.id], []);
    return ids.includes(layerId) ? true : false;
  }

  let mapActiveLayers = (window.sessionStorage.getItem("mapActiveLayers")) ? JSON.parse(window.sessionStorage.getItem("mapActiveLayers")) : null;
  
  //TODO this might allow someone to set the layers by default and then have them show up even if they are not premium

  if (!mapActiveLayers) {
    mapActiveLayers = [];
    layers.map((l) => {
      if (l.visibility === 'visible' && mapHasLayer(l.layers[0]))
        mapActiveLayers.push(l.layers[0]);
    });
  } else {
    layers.map((l) => {
      if (mapActiveLayers.includes(l.layers[0]) && mapHasLayer(l.layers[0])) {
        l.visibility = 'visible';
      } else {
        l.visibility = 'none';
      }
    });
  }

  layers.forEach((l) => {
    if (l.remove) {
      l["hide"] = true
      l.layers.forEach((id) => {
        if (mapHasLayer(id)) {
          map.setLayoutProperty(id, 'visibility', "none");
        }
      });
    }
    if (!l.hide) {
      l.layers.forEach((id) => {
        if (mapHasLayer(id)) {
          map.setLayoutProperty(id, 'visibility', l.visibility);
        }
      });
    }
  });

  function setLayerVisibility(el) {
    el.stopPropagation();
    if (el.target.type === 'checkbox') {
      this._layers = (el.target.dataset.layers.indexOf(",") > -1) ? el.target.dataset.layers.split(",") : [el.target.dataset.layers]
      
      if (mapHasLayer(el.target.dataset.id)) {
        if (!el.target.checked) {
          this._layers.forEach(l => {
            map.setLayoutProperty(l, 'visibility', 'none');
            const active = mapActiveLayers.filter(
              (a) => a !== l
            );
            mapActiveLayers = active;
            window.sessionStorage.setItem(
              'mapActiveLayers',
              JSON.stringify(mapActiveLayers)
            );
          })

        } else {
          this._layers.forEach(l => {
            map.setLayoutProperty(l, 'visibility', 'visible');
            mapActiveLayers.push(l);
            window.sessionStorage.setItem(
              'mapActiveLayers',
              JSON.stringify(mapActiveLayers)
            );
          })

        }
      } else {
        //map does not have layer and therefore is a premium layer and we should show the premium modal or toast
      }
    } else {
      el.target.classList.add('active');
      el.target.classList.add('pointer-events');
    }

    //React useEffect()
    // console.log(layers);
    // mapLayers.map((l) => {
    //   return l.visibility === "none"
    //     ? (l.visibility = "visible")
    //     : (l.visibility = "none");
    // });
    // setLayers(layers);
  }

  function setActive(el) {
    el.stopPropagation();
    if (el.target.type !== 'checkbox') {
      if (el.target.classList.contains('active')) {
        el.target.classList.remove('active');
        el.target.classList.remove('pointer-events');
      } else {
        el.target.classList.add('active');
        setTimeout(() => {
          el.target.classList.add('pointer-events');
        }, 500);
      }
    }
  }

  const layerControl = new MapboxGLButtonControl({
    className: 'map-ctrl-layers',
    title: 'Layer Control',
    clickHandler: setLayerVisibility,
    mouseoverHandler: setActive,
    mouseoutHandler: setActive,
    HTML: `
    ${
      premium
        ? ''
        : '<a title="Get Premium" class="bg-brandPrimary hover:bg-brandPrimary text-center px-5 py-2 text-xs font-medium tracking-wider text-white rounded-full transition ease-in duration-300" href="/">Get Premium</a><span class="px-2 py-1">Upgrade to Premium for access to all map layers.</span>'
    }
    <div class="inline-flex items-center mt-1"><span><img src="https://icongr.am/fontawesome/minus.svg?size=128&amp;color=4879DD"></span> USA Streams</div>
    <div class="inline-flex items-center mt-1"><span><img src="https://icongr.am/fontawesome/minus.svg?size=128&amp;color=14E20C"></span> Trout Streams</div>
    ${layers
      .map((l) => {
        if (!l.hide)
          return `
          <label class="inline-flex items-center mt-1 ${
            l.disabled ? 'opacity-60' : ''
          }" for="lc_${l.layers[0]}">
            <input type="checkbox" id="lc_${l.layers[0]}" ${
            l.disabled ? 'disabled' : ''
          } data-id="${l.layers[0]}" data-layers="${l.layers}" class="form-checkbox h-4 w-4 text-gray-600" ${
            l.visibility === 'visible' ? 'checked' : ''
          }><span class="ml-1"><span><img src="${l.icon}"></span>${
            l.name
          }</span>
          </label>
          `;
      })
      .join('')}<p><br></p>`,
  });
  return layerControl;
};

export default LayerControl;

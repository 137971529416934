import SingleQuestion from './SingleQuestion';

const Questions = ({ questions }) => {
  return (
    <div className='flex flex-col w-full md:w-1/2'>
      {questions &&
        questions.length > 0 &&
        questions.map((question, i) => {
          return (
            <SingleQuestion key={i} currentIndex={i} question={question} />
          );
        })}
    </div>
  );
};

export default Questions;

export default () => {
  const buttonStyle = "block text-center w-full bg-brandPrimary rounded-md text-white py-1 px-2 m-0.5 hover:opacity-90 duration-300";

  const pointPopup = (f) => {
    // console.log(f);
    const name = f.properties.name ? f.properties.name : null;
    const coords = f.geometry.coordinates;
    const lat = coords[1];
    const lng = coords[0];
    const html = `<img src="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${coords[0]},${coords[1]},17.2/300x300?access_token=pk.eyJ1IjoiYW5kcmV3Ym9lZGRla2VyIiwiYSI6ImNrNDliZzYxbjAyNGQzbW4yMnI4eDR3amsifQ.-p4QomGJMOINgPmR4zEQyw" alt='Parking Satellite View' />
    ${name ? `<strong>${name}</strong></br>` : ""}
    <a class="${buttonStyle}" href="https://www.google.com/maps/dir//${lat},${lng}/@${lat},${lng},12.23z" target="_blank">Get Directions</a>
    <a class="${buttonStyle}" href="https://forecast.weather.gov/MapClick.php?lat=${coords[1]}&lon=${coords[0]}#.YArKl8Vuc-Q" target="_blank">Get Weather</a>`;
    return {html: html, lngLat: f.geometry.coordinates}
  }

  const trailPopup = (f, lngLat) => {
    const trailUseAlias = {
      hikerpedestrian: "Hiking",
      bicycle: "Biking",
      packsaddle: "Horseback Riding",
      atv: "ATV",
      motorcycle: "Dirtbike",
      ohvover50inches: "OHV",
      snowshoe: "Snowshoe",
      corsscountryski: "Cross Country Skiing",
      dogsled: "Dogsled",
      snowmobile: "Snowmobile"
    };
    
    const name = (f.properties["name"]) ? `<strong>${f.properties["name"]}</strong><br>` : "";
    const permittedUses = [];
    Object.keys(trailUseAlias).map(u => {
      if (f.properties[u] && f.properties[u] === "Y") permittedUses.push(trailUseAlias[u])
    })
    const permittedUsesHTML = (permittedUses.length) ? "<br>Permitted Uses: " + permittedUses.toString().replace(/\,/g, ", ") : "";
    const area = (f.properties["sourceoriginator"]) ? `<em>${f.properties["sourceoriginator"]}</em><br>` : "";

    const coords = f.geometry.coordinates;
    const lat = coords[1];
    const lng = coords[0];
    const html = name + area + permittedUsesHTML + 
    `<a class="${buttonStyle}" href="https://www.google.com/maps/dir//${lat},${lng}/@${lat},${lng},12.23z" target="_blank">Get Directions</a>
    <a class="${buttonStyle}" href="https://forecast.weather.gov/MapClick.php?lat=${coords[1]}&lon=${coords[0]}#.YArKl8Vuc-Q" target="_blank">Get Weather</a>`;

    return {html: html, lngLat: lngLat}
  }


  //TODO add icon for premium access
  return [
    {
      layers: ["poi-label"],
      hide: true,
      popup: pointPopup
    },
    {
      layers: ["fffPremiumAccess"],
      name: "Access Points",
      visibility: "visible",
      icon: "/icons/Fish_Revised_gbeyyb.svg",
      premium: true,
      popup: pointPopup
    },
    {
      layers: ["fffParking"],
      name: "Parking",
      visibility: "visible",
      premium: true,
      icon: "/icons/parking_icon.svg",
      popup: pointPopup
    },
    {
      layers: ["stream-gages-active-0zf66t"],
      name: "Stream Gages",
      visibility: "none",
      premium: true,
      icon: "./icons/Water_Depth_Measuring_Revised_2_ejouol.svg",
      popup: async (f) => {
        const p = f.properties;
        //TODO convert this to a function that is imported here?
        const api = "https://waterservices.usgs.gov/nwis/iv/?format=json&sites=";
        const params = "&parameterCd=00060,00065&period=P30D";
        const data = await fetch(api + p.SITE_NO + params).then(res => res.json());
        console.log(data)
        function cleanValues(values) {
          return values.filter(v => Number(v.value) && Number(v.value) > -999)
        }
        function getAvgGageHeight(values) {
          return (values.reduce((i, v) => {
            return i + Number(v.value)
          }, 0) / values.length).toFixed(2)
        }
        let html;
        try {
          const flowRates = data.value.timeSeries[0].values[0].value;
          const flowRate = flowRates.at(-1).value;
          const gageHeights = data.value.timeSeries[1].values[0].value;
          const latestHeight = gageHeights.at(-1).value;
          const avgerage = getAvgGageHeight(cleanValues(gageHeights))
          html = `
          <strong>${p.STATION_NM}</strong><br>
          Annual Base Flow: ${(p.AVE * p.BFI_AVE).toFixed(0)} ft3/s<br>
          Current Flow Rate: ${flowRate} ft3/s
          Current Gage Height: ${latestHeight} ft (30-day avg: ${avgerage})
        `
        }catch(err) {
          html = `No information found for this Gage.`
        }
        return {html: html, lngLat: f.geometry.coordinates}
      }
    },
    {
      layers: ["fffBoatramps"],
      name: "Boatramps",
      visibility: "none",
      premium: true,
      popup: pointPopup,
      icon: "./icons/boat_launch_new2.svg",
    },
    {
      layers: ["fffTrailPoints"],
      name: "Trail Points",
      visibility: "none",
      popup: pointPopup,
      icon: "https://icongr.am/fontawesome/circle.svg?size=128&color=cd5c5c"
    },
    {
      layers: ["fffBridgePoints"],
      name: "Bridge Points",
      visibility: "none",
      popup: pointPopup,
      icon: "https://icongr.am/fontawesome/circle.svg?size=128&color=1F7D8C"
    },
    {
      layers: ["fffTrailheads"],
      name: "Trailheads",
      visibility: "none",
      popup: (f) => {
        const p = f.properties;
        let html = `
          ${p.name? `<strong>${p.name}</strong>` : "Unnamed Trailhead"}
        `;
        return {
          html: html,
          lngLat: f.geometry.coordinates
        }
      },
      icon: "/icons/hiking.png"
    },
    {
      layers: ["campsites"],
      name: "Camp Sites",
      visibility: "none",
      popup: (f) => {
        const p = f.properties;
        let html = `
          ${p.Area? `<strong>${p.Area}</strong><br><hr >` : ""}
          ${p.Description ? `<p>${p.Description}`: ""}
        `;
        return {
          html: html,
          lngLat: f.geometry.coordinates
        }
      },
      icon: "https://icongr.am/material/triangle-outline.svg?size=128&color=currentColor"
    },
    {
      name: "Hiking & Multi-Use Trails", //TODO create intersect points nad merge with access points layer
      layers: ["usgs_trails_case", "usgs_trails"], //NOTE the popup listener is on the first layer in the list, so making it on the thicker layer
      premium: true,
      icon: "https://icongr.am/fontawesome/minus.svg?size=128&color=DF706A",
      visibility: "none",
      popup: trailPopup,
      layerStyles: [
        {
          id: "usgs_trails_case",
          type: "line",
          source: "usgs_trails",
          _source: {
            type: "vector",
            tiles: [ "https://app.tileserv.com/tiles/public.usgs_terrestrial_trails/{z}/{x}/{y}.pbf"],
            minzoom: 8
          },
          "source-layer": "public.usgs_terrestrial_trails",
          paint: {
            "line-color": "indianred",
            "line-width": {"stops": [
              [10,2],
              [13,4],
              [18,12]
            ]},
            "line-opacity": {"stops": [[9,0.3],[15,0.9]]}
          }
        },
        {
          id: "usgs_trails",
          type: "line",
          source: "usgs_trails",
          _source: {
            type: "vector",
            tiles: [ "https://app.tileserv.com/tiles/public.usgs_terrestrial_trails/{z}/{x}/{y}.pbf"],
            minzoom: 8
          },
          "source-layer": "public.usgs_terrestrial_trails",
          paint: {
            "line-color": "white",//"#DF706A",//"#644906",
            "line-dasharray": [4,1],
            "line-width": 2,
            "line-opacity": {"stops": [ [4,0],[10,0],[13,0.2],[15,0.9]]}
          }
        }
      ]
    },
    {
      layers: ["designated-trout-water"],
      name: "Designated Trout Section",
      visibility: "visible",
      premium: true,
      icon: "https://icongr.am/fontawesome/square.svg?size=128&color=121212"
    },
    {
      layers: ["fffGoldStreams"],
      name: "Gold Streams<br>(predominantly wild fisheries)",
      premium: true,
      icon: "https://icongr.am/fontawesome/square.svg?size=128&color=F5D905",//"https://res.cloudinary.com/dauxj5oo3/image/upload/v1630471772/static-site-assets/public-site/icon-images/goldstreams_bugyli.png",
      visibility: "visible",
    },
    // {
    //   layers: ["fffSilverStreams"],
    //   name: "Silver Streams",
    //   premium: true,
    //   icon: "https://icongr.am/fontawesome/square.svg?size=128&color=d4d4d4",//"https://res.cloudinary.com/dauxj5oo3/image/upload/v1630472016/static-site-assets/public-site/icon-images/silverstreams_skuxhi.png",
    //   visibility: "visible",
    // },
    // {
    //   name: "Bronze Streams",
    //   layers: ["fffBronzeStreams"],
    //   premium: true,
    //   icon: "https://icongr.am/fontawesome/square.svg?size=128&color=EBA770",//"https://res.cloudinary.com/dauxj5oo3/image/upload/v1630472013/static-site-assets/public-site/icon-images/bronzestreams_r8xduj.png",
    //   visibility: "visible",
    // },
    // {
    //   name: "Easements",
    //   layers: ["fffEasements"],
    //   premium: true,
    //   icon: "https://icongr.am/fontawesome/square.svg?size=128&color=C0A070",///"https://res.cloudinary.com/dauxj5oo3/image/upload/v1630472614/static-site-assets/public-site/icon-images/landeasment_llrjfp.png",
    //   visibility: "none",
    // },
    {
      name: "Stream Easements",
      layers: ["fffEasements"],
      icon: "https://icongr.am/fontawesome/square.svg?size=128&color=D76FAA",//"https://res.cloudinary.com/dauxj5oo3/image/upload/v1630471998/static-site-assets/public-site/icon-images/streameasments_fof8nn.png",
      visibility: "none",
      premium: true
    },
    {
      name: "Topopgraphy",
      layers: ["contour-line", "contour-label", "hillshade"],
      popup: false,
      icon: "https://icongr.am/material/panorama.svg?size=128&color=a9b2a9",
    },
    {
      name: "Public Lands",
      layers: ["us_public_lands"],
      icon: "https://icongr.am/fontawesome/square.svg?size=128&color=D4A873",
      visibility: "none",
      premium: true,
      beforeLayer: "hillshade",
      layerStyles: [
        {
          id: "us_public_lands",
          type: "fill",
          source: "us_public_lands",
          _source: {
            type: "vector",
            tiles: ["https://app.tileserv.com/tiles/public.entire_us_public_lands/{z}/{x}/{y}.pbf"],
            minzoom: 8
          },
          "source-layer": "public.entire_us_public_lands",
          paint: {
            "fill-color": "hsl(33, 53%, 64%)",
            "fill-opacity": 0.8
          },
        },
        // {
        //   id: "us_public_lands_outline",
        //   type: "line",
        //   source: "us_public_lands",
        //   _source: {
        //     type: "vector",
        //     url: "https://tiles.zerospatial.com/us_public_lands/tilejson"
        //   },
        //   "source-layer": "us_public_lands",
        //   paint: {
        //     "line-color": "hsl(33, 53%, 24%)",
        //     "line-opacity": 0,
        //     "line-width": {"stops": [
        //       [7,1],
        //       [12,2],
        //       [18,4]
        //     ]},
        //   },
        // }
      ],
      //
      popup: (f, lngLat) => {
        const fields = ["loc_nm", "loc_own", "loc_ds", "state_nm", "pub_access"]
        const aliases = [
          "Name", "Owner", "Description", "State", "Access"
        ]
        const html = `${fields.map((v,i) => {
          if (f.properties[v]) {
            const value = (f.properties[v] === "OA") ? "Open Access" : (f.properties[v] === "RA") ? "Seasonal Restrictions" : (f.properties[v].toLowerCase() === "fee") ? "Private" : f.properties[v]
            return (aliases[i] === "Name") ? `<strong>${value}</strong><hr>` : `<strong>${aliases[i]}:</strong> ${value}<br>`
          }else{
            return ""
          }
        }).join("")}

        `
        return {html : html, lngLat: lngLat}
      }
    },
    {
      name: "EPA NHD Streams",
      layers: ["nhd_streams"],
      visibility: "visible",
      premium: false,
      beforeLayer: "wetland",
      layerStyles: [{
        id: "nhd_streams",
        type: "line",
        source: "nhd_streams",
        _source: {
          type: "vector",
          tiles: ["https://app.tileserv.com/tiles/public.fff_nhd_streams/{z}/{x}/{y}.pbf"],
          minzoom: 8
        },
        "source-layer": "public.fff_nhd_streams",
        minzoom: 8,
        paint: {
          // "line-color": "red",
          // "line-color": ["interpolate", ["linear"], ["zoom"], 
          //   0, "hsla(202, 99%, 65%, 0.5)",
          //   9, "#4879DD",
          // ],
          "line-color": ["case",
            ["==", ["get", "is_trout"], 1], "rgba(20, 180, 12, 0.8)","#4879DD"
          ],
          "line-width": ["interpolate", ["linear"],["zoom"],
            7,  ["case", ["has", "gnis_id"], 1, 0],
            8,  ["case", ["has", "gnis_id"], 1, 1],
            12, ["case", ["has", "gnis_id"], 2.5, 1],
            17, ["case", ["has", "gnis_id"], 10, 3],
          ],
          "line-opacity": ["interpolate", ["linear"],["zoom"],
            7,  ["case", ["has", "gnis_id"], 0.4, 0],
            9,  ["case", ["has", "gnis_id"], 0.4, 0],
            10, ["case", ["has", "gnis_id"], 1, 0.7],
          ]
        },
        layout: {
          visibility: "visible"
        }
      }],
      hide: true,
      popup: (f, lngLat, premium) => {
        let html = f.properties.gnis_name ? `<h3 class="text-lg font-semibold">${f.properties.gnis_name}</h3>` : null;
        if (premium && f.properties.species) html += "Species: " + f.properties.species + ""
        return {html : html, lngLat: lngLat}
      }
    },

    // {
    //   name: "nhd_trout_streams",
    //   hide: true,
    //   popup: false,
    //   layers: ["nhd_trout_streams"],
    //   visibility: "none",
    //   premium: false,
    //   beforeLayer: "water",
    //   layerStyles: [{
    //     id: "nhd_trout_streams",
    //     type: "line",
    //     source: "nhd_streams",
    //     _source: {
    //       type: "vector",
    //       url: "https://tiles.zerospatial.com/nhd_streams/tilejson"
    //     },
    //     "source-layer": "nhd_streams",
    //     minzoom: 7,
    //     paint: {
    //       "line-color": "rgba(20, 226, 12, 0.6)",
    //       // "line-width": ["interpolate", ["linear"], ["zoom"], 7,2,10,4,16,26]
    //       "line-width": ["interpolate", ["linear"],["zoom"],
    //         7,  ["case", ["has", "gnis_id"], 1, 0],
    //         9,  ["case", ["has", "gnis_id"], 1, 0],
    //         12,  ["case", ["has", "gnis_id"], 2, 1],
    //         17, ["case", ["has", "gnis_id"], 6, 3],
    //       ],
    //       "line-opacity": ["interpolate", ["linear"],["zoom"],
    //         7,  ["case", ["has", "gnis_id"], 0.1, 0],
    //         9,  ["case", ["has", "gnis_id"], 0.4, 0],
    //         10, ["case", ["has", "gnis_id"], 1, 0.7],
    //       ]
    //     },
    //     filter: ["==", ["get", "is_trout"], 1],
    //     layout: {
    //       "visibility": "none"
    //     }
    //   }]
    // },
    {
      //NOTE this is a way to remove layers from the map that we have replaced
      layers: ["pa-tn-wi-mn-merged-2d90fi"],
      remove: true
    },
    {
      name: "Streams",
      layers: ["map-streams-w-attributes-64se6m"],
      icon: null,
      // popup: (f, lngLat) => {
      //   const html = `<strong>${(f.properties.name) ? f.properties.name : "Unnamed Stream"}</strong>
      //   ${(f.properties.species && f.properties.species !== undefined) ? "<br>Species: " + f.properties.species : ""}`
      //   return {html: html, lngLat: lngLat}
      // },
      remove: true,
      hide: true //could change to layerControl: false //F5D905 //d4d4d4 EBA770 C0A070 D76FAA
    },
    {
      layers: ["nhd_streams"],
      beforeLayer: "waterway",
      hide: true,
      minzoom: 8,
      layerStyles: [{
          id: "nhd_streams",
          type: "line",
          source: "nhd_legacy",
          _source: {
            type: "vector",
            url: "https://tiles.zerospatial.com/nhd_legacy/tilejson",
            minzoom: 8
          },
          "source-layer": "nhd_streams",
          paint: {
            "line-color": "#B6DCFC",
            // "line-color": "red",
            "line-width": 3,
            "line-opacity": {
              "stops": [
                [0,0],
                [7,0.1],
                [10,0.5],
                [14,0.8]
              ]
            }
          },
        },
      //   {
      //     id: "nhd_areas",
      //     type: "fill",
      //     source: "nhd_legacy",
      //     _source: {
      //       type: "vector",
      //       url: "https://tiles.zerospatial.com/nhd_legacy/tilejson"
      //     },
      //     "source-layer": "nhd_areas",
      //     paint: {
      //       "fill-color": "blue",
      //       "fill-opacity": {
      //         "stops": [
      //           [0,0],
      //           [6,0],
      //           [14,0.8]
      //         ]
      //       }
      //     },
      //   }
      ]
    },
    {
      name: "hide-mapbox-trails",
      layers: ["road-path", "road-path-bg"],
      remove: true
    },
    {
      name: "hide-waterway",
      layers: ["waterway"],
      remove: true
    },

  ];
};

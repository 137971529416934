import React from 'react';

const ViewHeader = ({ title, subTitle }) => {
  return (
    <div>
      <h1 className='inline-block text-gray-800 font-bold text-5xl mb-4'>
        {title}
      </h1>
      {subTitle && (
        <p className='inline-block text-gray-600 font-bold text-sm'>
          {subTitle}
        </p>
      )}

      <hr className='w-40 mb-14 border-4 border-brandPrimary rounded-md ' />
    </div>
  );
};

export default ViewHeader;

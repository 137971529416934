// see https://codepen.io/roblabs/pen/zJjPzX

class MapboxGLButtonControl {
  constructor({ 
    className = "",
    groupClassName = "",
    title = "",
    HTML = "",
    clickHandler = function() {},
    mouseoverHandler = function() {},
    mouseoutHandler = function() {} 
  }) {
    this._className = className;
    this._groupClassName = groupClassName
    this._title = title;
    this._HTML = HTML
    this._clickHandler = clickHandler;
    this._mouseoverHandler = mouseoverHandler;
    this._mouseoutHandler = mouseoutHandler;

  }

  onAdd(map) {
    this._map = map;
    this._el = (this._HTML) ? document.createElement("div") : document.createElement("button");
    this._el.className = `mapboxgl-ctrl-icon ${this._className}`;
    this._el.type = (this._HTML) ? "" : "button";
    this._el.title = this._title;
    this._el.onclick = this._clickHandler;
    this._el.onmouseenter = this._mouseoverHandler;
    this._el.onmouseleave = this._mouseoutHandler

    if (this._HTML) {
        this._el.innerHTML = this._HTML
    }
    this._container = document.createElement("div");
    this._container.className = `mapboxgl-ctrl-group mapboxgl-ctrl ${this._groupClassName}`;
    this._container.appendChild(this._el);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export default MapboxGLButtonControl;

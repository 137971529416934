import React from 'react';
import { Link } from 'react-router-dom';
// import Message from '../icons/Message';
import UserIcon from '../icons/UserIcon';

const UserCard = ({ user }) => {
  return (
    <div className='w-full bg-white rounded-lg'>
      <div className='p-12 flex flex-col justify-center items-center'>
        {/* User avatar */}
        <div className='mb-8'>
          <img
            className='object-center object-cover rounded-full h-36 w-36'
            src={user.profilePicture.url || user.profilePicture}
            alt='User Profile'
          />
        </div>
        {/* User info */}
        <div className='text-center'>
          <p className='text-xl text-gray-700 font-bold mb-2'>
            {user.firstName} {user.lastName}
          </p>
          {user.userLocation.locationName && (
            <span className='mr-3 text-gray-400'>
              {user.userLocation.locationName}
            </span>
          )}
          {/* <span className='text-gray-400'>
            <span className='mr-3 border-r border-gray-200  max-h-0'></span>
            Expert
          </span> */}
        </div>
      </div>
      {/* Footer */}
      <Link to={`/user-profile/${user.auth0_ID}`}>
        <div className='cursor-pointer flex items-center justify-center border-t border-gray-100 py-2'>
          <span className='text-brandPrimary flex items-center '>
            <span className='mr-2'>
              <UserIcon />
            </span>
            View Profile
          </span>
          {/* <span className='text-brandPrimary flex items-center '>
          <span className='mr-2'>
            <Message />
          </span>
          Send Message
        </span> */}
        </div>
      </Link>
    </div>
  );
};

export default UserCard;

import React from 'react';

/**
 *
 * @param {*} Takes in information about a page
 * @returns returns a reusable header image with page information
 */

const PageHeader = ({ pageTitle, subTitle, extra }) => {
  return (
    <div
      className='flex justify-center items-center p-8 relative'
      style={{
        background: `url(${'https://res.cloudinary.com/dauxj5oo3/image/upload/v1636144838/static-site-assets/public-site/landing/image_13_aytozt.png'})
      `,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: 400,
      }}>
      <span
        id='blackOverlay'
        className='w-full h-full absolute opacity-10 bg-black'></span>
      <div className='text-center'>
        <h1 className='text-white text-5xl tracking-wide mb-2 font-bold'>
          {pageTitle}
        </h1>
        <span className='text-white tracking-wide font-light font-semibold'>
          {subTitle}
        </span>
      </div>
      {extra && extra}
    </div>
  );
};

export default PageHeader;

import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

export default function Alert({
  children,
  badge,
  text = 'text goes here',
  show,
  onClose,
}) {
  return (
    <div class='text-start py-4'>
      <Link
        to='/settings'
        class='p-2  animate-pulse bg-brandPrimary items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex'
        role='alert'
      >
        {badge ? (
          <span class='flex animate-pulse rounded-full text-black bg-white uppercase px-2 py-1 text-xs font-bold mr-3'>
            {badge}
          </span>
        ) : null}
        <span class='font-semibold pl-2 mr-2 text-left flex-auto'>{text}</span>
        <svg
          class='fill-current opacity-75 h-4 w-4'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
        >
          <path d='M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z' />
        </svg>
      </Link>
    </div>
  )
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

/**
 *
 * @param {course} Object that contains course information
 * @returns Cards that displays information about the course
 */

const CourseCard = ({ course, purchased, isPublic }) => {
  const [showMore, setShowMore] = useState(false);

  const getText = (text) => {
    // For Text that is shorter than desired length
    if (text.length <= 268) return text;
    // If text is longer than desired length & showMore is true
    if (text.length > 268 && showMore) {
      return (
        <>
          <p>
            {text}{' '}
            <button
              className='text-brandPrimary'
              onClick={(e) => {
                e.stopPropagation();
                setShowMore(false);
              }}>
              Show Less
            </button>
          </p>
        </>
      );
    }
    // If text is longer than desired length & showMore is false
    if (text.length > 268) {
      return (
        <>
          <p>
            {text.slice(0, 268)}...{' '}
            <button
              className='text-brandPrimary'
              onClick={(e) => {
                e.stopPropagation();
                setShowMore(true);
              }}>
              See More
            </button>
          </p>
        </>
      );
    }
  };

  return (
    <div key={course.name} className='xl:w-1/4 md:w-1/2 p-4'>
      <div className='bg-gray-50 p-3 rounded-lg shadow-md'>
        <div
          className='h-80 rounded mb-6 overflow-hidden mx-auto'
          style={{
            background: ` url(${course.coursePreviewPic})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}>
          {/* <img
            className='h-full row-full object-contain'
            src={course.coursePreviewPic}
            alt='content'
          /> */}
        </div>

        <div className='flex items-center justify-between'>
          <h3 className='tracking-widest text-brandPrimary text-xs font-medium title-font'>
            {/* {course.difficulty} */}
          </h3>
        </div>

        <h2 className='text-lg text-brandPrimary font-medium title-font font-semibold mb-4'>
          {course.name}
        </h2>
        {/* Prices */}
        {!purchased && (
          <>
            {/* Only show if the course has a price */}
            {course.price && (
              <h2 className='text-lg text-blueSecondary font-medium title-font font-semibold mb-4 line-through'>
                Price: {course.price}
              </h2>
            )}

            {/* Only show if there is a sale */}
            {course.price2 && (
              <h2 className='text-lg text-red-400 font-medium title-font font-bold mb-4'>
                Sale: {course.price2}
              </h2>
            )}
          </>
        )}
        {/* Gets the about for the cours */}
        {getText(course.about)}
        <div className='mt-4'>
          {!isPublic && (
            <>
              {/* Optionally display the purchased link depending on if the user has bought the course or not */}
              {purchased ? (
                <Link
                  to={`/course/${course.id}`}
                  className='block text-center w-full bg-brandPrimary rounded-md text-white py-1 px-2 hover:opacity-90 duration-300'>
                  View Course
                </Link>
              ) : (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={course.purchaseUrl}
                  className='block text-center w-full bg-brandPrimary rounded-md text-white py-1 px-2 hover:opacity-90 duration-300'>
                  Purchase Course
                </a>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseCard;

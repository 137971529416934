export const levels = [
  { label: '2 years or less', value: '2 years or less' },
  { label: '3-5 years', value: '3-5 years' },
  { label: '6+ years', value: '6+ years' },
];

export const fishingLocations = [
  { label: 'Southwest', value: 'Southwest' },
  { label: 'Northwest', value: 'Northwest' },
  { label: 'Midwest', value: 'Midwest' },
  { label: 'Southeast', value: 'Southeast' },
  { label: 'Northeast', value: 'Northeast' },
  { label: 'Other', value: 'Other' },
];

export const waterTypes = [
  { label: 'Lakes', value: 'Lakes' },
  { label: 'Rivers', value: 'Rivers' },
  { label: 'Streams', value: 'Streams' },
  { label: 'Alpine lakes', value: 'Alpine lakes' },
  { label: 'Saltwater', value: 'Saltwater' },
];

export const fishingStyles = [
  { label: 'Basic streamer', value: 'Basic streamer' },
  { label: 'Swinging streamers', value: 'Swinging streamers' },
  { label: 'Nymphing', value: 'Nymphing' },
  { label: 'Euro nymphing', value: 'Euro nymphing' },
  { label: 'Dry fly', value: 'Dry fly' },
  { label: 'Wet fly', value: 'Wet fly' },
];

export const fishTypes = [
  { label: 'Arctic Grayling', value: 'Arctic Grayling' },
  { label: 'Atlantic Salmon', value: 'Atlantic Salmon' },
  { label: 'Crappie', value: 'Crappie' },
  { label: 'Bluegill', value: 'Bluegill' },
  { label: 'Brook Trout', value: 'Brook Trout' },
  { label: 'Rainbow Trout', value: 'Rainbow Trout' },
  { label: 'Brown Trout', value: 'Brown Trout' },
  { label: 'Bull Trout', value: 'Bull Trout' },
  { label: 'Chinook Salmon', value: 'Chinook Salmon' },
  { label: 'Chum Salmon', value: 'Chum Salmon' },
  { label: ' Coho Salmon', value: ' Coho Salmon' },
  { label: 'Lake Trout', value: 'Lake Trout' },
  { label: 'Largemouth Bass', value: 'Largemouth Bass' },
  { label: 'Smallmouth Bass', value: 'Smallmouth Bass' },
  { label: 'Muskellunge', value: 'Muskellunge' },
  { label: 'Northern Pike', value: 'Northern Pike' },
  { label: 'Walleye', value: 'Walleye' },
  { label: 'Common Carp', value: 'Common Carp' },
  { label: 'Westslope Cutthroat Trout', value: 'Westslope Cutthroat Trout' },
  { label: 'Striped Bass', value: 'Striped Bass' },
  { label: 'Steelhead', value: 'Steelhead' },
  { label: 'White Bass', value: 'White Bass' },
  { label: 'Yellow Perch', value: 'Yellow Perch' },
  { label: 'Sunfish', value: 'Sunfish' },
  { label: 'Tarpon', value: 'Tarpon' },
  { label: 'Golden Trout', value: 'Golden Trout' },
  { label: 'Tiger Trout', value: 'Tiger Trout' },
  { label: 'Apache Trout', value: 'Apache Trout' },
  { label: 'Greenback Cutthroat Trout', value: 'Greenback Cutthroat Trout' },
  { label: 'Silver Trout,', value: 'Silver Trout,' },
  {
    label: 'Yellowstone Cutthroat Trout',
    value: 'Yellowstone Cutthroat Trout',
  },
  { label: 'Bonneville Cutthroat Trout', value: 'Bonneville Cutthroat Trout' },
  { label: 'Dolly Varden', value: 'Dolly Varden' },
  { label: 'European Brown Trout', value: 'European Brown Trout' },
  { label: 'New Fish', value: 'New Fish' },
];

export const selectStyles = {
  container: () => ({
    padding: '4px 8px',
    border: '2px solid #E4E4E7',
    backgroundColor: '#fff',
    borderRadius: 10,
    position: 'relative',
  }),
  control: (base) => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
    position: 'relative',
  }),
};

export const defaultLocation = {
  lat: 39.14363635021468,
  lng: -94.54738363623619,
};

import React from 'react';

/**
 *
 * @param {stat} contains stat details
 * @returns card for a specific card
 */

const StatCard = ({ stat }) => {
  return (
    <div className='flex items-center justify-around p-6 bg-white w-full rounded-xl space-x-2 shadow-lg'>
      <div>
        {/* title */}
        <span className='text-md font-semibold text-gray-400'>
          {stat.title}
        </span>
        {/* stat */}
        <h1 className='text-2xl font-bold text-blueTertiary'>
          {stat.statNumber}
        </h1>
      </div>
      {/* Icon */}
      <div className='text-brandPrimary'>{stat.icon}</div>
    </div>
  );
};

export default StatCard;

import ChartBoard from '../icons/ChartBoard';
import DoubleChevronUp from '../icons/DoubleChevronUp';
import Refresh from '../icons/Refresh';
import UserGroup from '../icons/UserGroup';

export const statData = [
  {
    title: 'Chat History',
    icon: <DoubleChevronUp />,
    statNumber: 20,
  },
  // {
  //   title: 'Anglers',
  //   icon: <UserGroup />,
  //   statNumber: 25,
  // },
  {
    title: 'Marked Waypoints',
    icon: <ChartBoard />,
    statNumber: 5,
  },
  {
    title: 'Hours Logged',
    icon: <Refresh />,
    statNumber: 68,
  },
];

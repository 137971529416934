import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router';

const Auth = () => {
  const { loginWithRedirect, user, isLoading, isAuthenticated } = useAuth0();

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user) {
        await loginWithRedirect();
      }
    })();
  }, [isLoading, user, loginWithRedirect]);
  // Redirect for after user is authenticated -> we had to do it this way because after login we have to redirect the user back here instead of dashboard-home
  if (isAuthenticated) {
    return <Redirect to='/dashboard-home' />;
  }

  return null;
};

export default Auth;

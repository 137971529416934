import { mapHasLayer } from "./mglHelpers";

export function mapClearHighlight(map) {
  const _layers = map.getStyle().layers;
  const highlightLayers = _layers.filter((l) =>
    l.id.includes("highlightLayer")
  );
  if (highlightLayers.length > 0) {
    highlightLayers.forEach((h) => {
      if (mapHasLayer(map, h.id)) {
        map.setLayoutProperty(h.id, "visibility", "none");
        map.removeLayer(h.id);
      }
    });
  }
}

export function mapSetHighlight(map, feature) {
  console.log(feature)
  
  const idField = (feature.layer.metadata && feature.layer.metadata.idField) ? feature.layer.metadata.idField :
    feature.properties && feature.properties.id ? "id" : 
    feature.properties && feature.properties.fid ? "fid" : null;
  if (!idField) return

  if (!mapHasLayer(map, "highlightLayer-" + feature.layer.id)) {
    map.addLayer({
      id: "highlightLayer-" + feature.layer.id,
      source: feature.source,
      "source-layer": feature.sourceLayer ? feature.sourceLayer : "",
      type: ["line", "fill"].includes(feature.layer.type) ? "line" : "circle",
      paint: ["line", "fill"].includes(feature.layer.type)
        ? {
          "line-color": "yellow",
          "line-width": 6,
        }
        : {
          "circle-color": "yellow",
          "circle-radius": (feature.layer.paint["circle-radius"]) ? feature.layer.paint["circle-radius"] : 8,
          "circle-stroke-width": 2,
          "circle-stroke-color": "yellow",
          "circle-opacity": 1,
        },
      filter: [
        "==",
        ["get", idField],
        feature.properties[idField],
      ],
      layout: {
        visibility: "none",
      },
    });
  }

  map.setLayoutProperty(
    "highlightLayer-" + feature.layer.id,
    "visibility",
    "visible"
  );
}

import { defaultLocation } from '../constants/variables/profileOptions'
/**
 *
 * @param {*} userInfo Information from Auth0 about user
 * @param {*} cb Any callback you would like to pass to perform after creation
 * @returns
 */

export default function createUser(userInfo, cb) {
  // Establish Variables
  const myStorage = window.sessionStorage
  const isGoogleUser = userInfo.sub.charAt(0) === 'g'

  // create usrObj to send to DB
  const userObj = {
    auth0_ID: myStorage.getItem('userId'),
    firstName: isGoogleUser ? userInfo.given_name : 'New',
    lastName: isGoogleUser ? userInfo.family_name : 'Angler',
    email: userInfo.email,

    isPremium: {
      trialMembership: false,
      trialExp: null,
      isCurrent: false,
    },
    isBusiness: false,
    showProfile: true,
    userLocation: defaultLocation,
    profilePicture: isGoogleUser
      ? userInfo.picture
      : 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg',
    coverImage:
      'https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80',
    bio: '',
    userType: 'BASIC',
    purchasedCourses: [],
    profileData: {
      fishingLocation: [],
      experienceLevel: [],
      typeOfWater: [],
      typesOfFish: [],
      fishingStyle: [],
      catchAndRelease: true,
    },
    businessData: {},
    firstLogin: true,
  }
  // Creat User in OUR DB
  return new Promise((resolve, reject) => {
    fetch(`/.netlify/functions/createUser`, {
      method: 'POST',
      body: JSON.stringify(userObj),
    })
      .then((res) => res.json())
      .then((data) => {
        if (cb) cb(data)
        return resolve(data)
      })
  })
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { sideNavRoutes } from '../routes/sideNavRoutes';
import LogOutIcon from '../icons/LogOutIcon';
import { Menu } from '../icons/Menu';
import { Close } from '../icons/Close';
import logo from '../img/logos/logoWhite.svg';

const SideBar = (props) => {
  const { logout } = useAuth0();
  const { pathname } = props.location;
  const { isAuthenticated } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogOut = () => {
    logout({ returnTo: window.location.origin });
    window.sessionStorage.setItem('isLoggedIn', 'false');
    window.sessionStorage.clear();
    window.localStorage.clear();
  };

  return (
    <>
      {isAuthenticated && (
        <div className={`relative block md:hidden`}>
          {!menuOpen && (
            <button
              className='h-8 w-8 rounded-md bg-brandPrimary text-white absolute top-3 left-2 flex items-center justify-center fade-in !delay-1000 shadow-md'
              style={{ zIndex: 10 }}
              onClick={() => setMenuOpen((menuOpen) => !menuOpen)}>
              <Menu />
            </button>
          )}
          <div
            className={`fixed flex flex-col left-0 bg-slate-600 dark:bg-gray-900 h-full text-white transition-all duration-300 border-none z-10 sidebar ${
              pathname === '/purchase-success' && 'hidden'
            } ${menuOpen === true ? 'w-64' : 'w-0'}`}>
            <div className='flex justify-end mt-5 mr-3 z-10 h-8'>
              {menuOpen && (
                <button
                  className='h-8 w-8 text-white z-10'
                  onClick={() => setMenuOpen((menuOpen) => !menuOpen)}>
                  <Close />
                </button>
              )}
            </div>

            <div className='overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow h-screen'>
              <ul className='flex flex-col py-4'>
                <li className='mx-auto mt-1 mb-7'>
                  <img src={logo} alt='fly fish finder logo h-8 w-8' />
                </li>
                {/* Routes / Links */}
                {sideNavRoutes &&
                  sideNavRoutes.map((route) => (
                    <li
                      key={route.name}
                      className='focus:outline-none focus:border-0'>
                      <Link
                        className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-brandPrimary text-white-600 hover:text-white-800 pr-6 transition-all duration-300 ease focus:border-0`}
                        to={route.route}>
                        <div
                          className={`inline-flex justify-center items-center ${
                            pathname === route.route
                              ? 'bg-brandPrimary text-white-800 p-2 rounded ml-2'
                              : 'ml-4 '
                          }`}>
                          <span className='w-6'>{route.icon}</span>
                        </div>
                        <span
                          className={`text-sm tracking-wider truncate  ${
                            pathname === route.route ? 'font-bold ml-3' : 'ml-5'
                          }`}>
                          {route.name}
                        </span>
                      </Link>
                    </li>
                  ))}
              </ul>
              {/* Log Out */}
              <ul className='mb-10'>
                <li className='mt-auto'>
                  <a
                    href='#!'
                    onClick={handleLogOut}
                    className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-brandPrimary dark:hover:bg-gray-600 text-white-600 hover:text-white-800  pr-6 transition-all duration-300 ease`}>
                    <span className='inline-flex justify-center items-center ml-3 w-6'>
                      <LogOutIcon />
                    </span>
                    <span className='ml-2 text-sm tracking-wide truncate'>
                      Log Out
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(SideBar);
